import { Flex, HStack } from "@chakra-ui/react";
import { FilterButton, } from "components/molecules";
import { Euro, MapPin, SlidersVertical } from "lucide-react";
import { StatusEnum } from "utils";

const filters = {
  [StatusEnum.Tenant]: [
    // {icon: <MapPin style={{marginRight: '5px'}} size={"16px"} />, text: "Paderborn"},
    {icon: <Euro style={{marginRight: '5px'}} size={"16px"} />, text: "Budget"}, 
    {icon: <SlidersVertical style={{marginRight: '5px'}} size={"16px"} />, text: "Filters"}
  ],
  [StatusEnum.Renter]: [
    {icon: <MapPin style={{marginRight: '5px'}} size={"16px"} />, text: "Paderborn"},
    {icon: <Euro style={{marginRight: '5px'}} size={"16px"} />, text: "Budget"}, 
    {icon: <SlidersVertical style={{marginRight: '5px'}} size={"16px"} />, text: "Filters"}
  ]
}

export const SubHeader = ({status}: {status: StatusEnum}) => {
  return(
    <Flex mb="6" px={"8"} py={4} maxWidth={'100vw'} border={'1px solid rgba(216, 220, 224, 1)'} background={'rgba(247, 247, 247, 1)'} alignItems={'flex-start'} flexDir={'column'}>
      {/* <Title1 marginBottom="2">{headerText[status]}</Title1> */}
      <HStack flexWrap={'wrap'}>
        
        {/* <InputField 
        name="here"
        isRequired={false}
        /> */}
        {filters[status].map((filter, i) => (
          <FilterButton key={i} {...filter} />
        ))}
      </HStack>
    </Flex>
  );
}
import { Grid, GridItem, theme } from "@chakra-ui/react";
import { MinusIcon, PlusIcon } from "lucide-react";
import { BackButton } from "../../Button";

type OptionGroupProps = {
  options: Array<string>,
  selected: Array<string>,
  onChange: (vals: string[]) => void,
  columns: number | number[],
  nonFunctional?: boolean ,// if true, will only display and not have any functionality
  fontSize?: string
}

export const OptionGroup = ({
  options,
  selected,
  columns,
  onChange,
  nonFunctional,
}: OptionGroupProps) => {
  const onChangeItem = (item: string) => {
    onChange(selected.includes(item) ? selected.filter((i: string) => i !== item) : selected.concat([item]))
  }
  
  return(
    <Grid 
      templateRows='repeat(1, 1fr)'
      templateColumns={
        typeof columns === "number" ? 
        `repeat(${columns}, 1fr)` : 
        columns.map(c => `repeat(${c}, 1fr)`)
      }
      gap={4}
      width={nonFunctional ? "100%" : "auto"}
    >
      {options ? (
        options.map((item, index) => (
          <GridItem colSpan={1} key={`${index}-${item}`}>
            <BackButton
              textStyle="light"
              fontSize="sm" // Directly applied fontSize here
              width="100%"
              onClick={() => onChangeItem(item)}
              style={
                selected.includes(item)
                  ? { background: theme.colors.green[900], color: "white" }
                  : {}
              }
            >
              {nonFunctional ? (
                <></>
              ) : selected.includes(item) ? (
                <MinusIcon size={17} />
              ) : (
                <PlusIcon size={17} />
              )}{" "}
              {item}
            </BackButton>
          </GridItem>
        ))
      ) : (
        <></>
      )}
    </Grid>
  );
}

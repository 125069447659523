import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Formik, FormikHelpers, FormikValues, useFormikContext } from 'formik';
import { Box, Flex, Spacer, VStack } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';

import { useNotifications, PageZero, ProgressSidebar } from 'components/organisms';
import { BackButton, NextButton, SecondaryButton } from 'components/molecules';
import { RegisterSchema } from 'validation';
import { useCreateProperty, useCurrentPage, useRegister } from 'lib';
import { initialValuesSignup, splt, StatusEnum } from 'utils';


// TODO: Refactor code

const FlexProps = {
  alignItems:'initial', gap:'0', height:'100%', padding:'5% 5%'
}

const EnumFlexProps = {
  alignItems:'center', height:"fit-content",
  gap:'0', padding:'5% 5%',
  justifyContent:'center', margin:'auto',
  width:['100%','fit-content']
}

const EnumFlex = ({children, isEnum}:any) => {
  if (isEnum){
    return(
      <VStack {...EnumFlexProps}>
        {children}
      </VStack>
    );
  }
  else{
    return(
      <VStack {...FlexProps}>
        {children}
      </VStack>
    );
  }
}

export type FormValues = {
  [key: string]: string;
};

const DisplayPages = (): JSX.Element => {
  let { allPages, setActivePage, activePageIndex: activePage, Component: CurrentPage, enum: isEnum } = useCurrentPage();
  let { values, validateField, setTouched, errors, setFieldValue }: FormikValues = useFormikContext<FormikValues>();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [activePage])
  
  const setCompleted = (i: number) => {
    return allPages.map((p, index) => {
      if (index === i){
        p.completed = true;
        return p;
      }
      else return p;
    })
  } 

  const setPageToCompleteAndGoTo = (c: number, n: number) => {
    setCompleted(c);
    setActivePage(n);
  }

  const validateFields = (fields: Array<string>): Promise<void>[] => fields.map(field => validateField(field));
  const isEmpty = (fields: Array<string>): boolean => {
    if (fields.length > 0) {
      return fields.some(f => {
        if (f.includes('moveInDate')){
          if (values[splt(f,0)].moveIn === 'later'){
            return !values[splt(f,0)].moveInDate;
          }
          else{
            return false;
          }
        }
        else if (f.includes('moveOutDate')){
          if (values[splt(f,0)].moveOut === 'later'){
            return !values[splt(f,0)].moveOutDate;
          }
          else{
            return false;
          }
        }
        else if (f === 'tenantData.phoneNumber' || f === 'tenantData.photoHighlights'){
          return false;
        }
        else if (f === 'property.roommateDetails'){
          return values[splt(f,0)][splt(f,1)].length === values.property.roommates ? values[splt(f,0)][splt(f,1)].some((v: any) => !v.name) : true;
        }
        else{
          // checking if the value is not empty, and allowing zero (0) in case if it is a number
          return typeof values[splt(f,0)][splt(f,1)] === "number" ? (!values[splt(f,0)][splt(f,1)] && values[splt(f,0)][splt(f,1)] !== 0) : !values[splt(f,0)][splt(f,1)];
        }
      })
    }
    else{return false}
  }
  const noErrors = (fields: Array<string>): boolean => {
    if (fields.length > 0){
      return fields.some(f => {
        if (f.includes('moveInDate')){
          if (values[splt(f,0)].moveIn === 'later'){
            return !errors[splt(f,0)]?.moveInDate;
          }
          else{
            return true;
          }
        }
        else if (f.includes('moveOutDate')){
          if (values[splt(f,0)].moveOut === 'later'){
            return !errors[splt(f,0)]?.moveOutDate;
          }
          else{
            return true;
          }
        }
        else{
          return f.includes('.') ? (errors[splt(f,0)] ? !errors[splt(f,0)][splt(f,1)] : !false) : !errors[f];
        }
      }) 
    }
    else{return true}
  }
  const setFieldsTouched = (fields: Array<string>, val: boolean) => {
    console.log(`touc`, fields, fields.reduce((v, field) => {
      if (field.startsWith('property')){
        if (field === 'property.roommateDetails'){
          return {...v, property: {...v.property, [splt(field,1)]: values[splt(field,0)][splt(field,1)].map((_: any) => ({name: val}))}};
        }
        return {...v, property: {...v.property, [splt(field,1)]: val}};
      }
      if (field.startsWith('tenantData')){
        return {...v, user: {...v.user, tenantData: {...v.user.tenantData, [splt(field,1)]: val}}};
      }
      return {...v, user: {...v.user, [splt(field,1)]: val}};
    }, {user: {tenantData: {}}, property: {}}))
    setTouched(fields.reduce((v, field) => {
      if (field.startsWith('property')){
        if (field === 'property.roommateDetails'){
          return {...v, property: {...v.property, [splt(field,1)]: values[splt(field,0)][splt(field,1)].map((_: any) => ({name: val}))}};
        }
        return {...v, property: {...v.property, [splt(field,1)]: val}};
      }
      if (field.startsWith('tenantData')){
        return {...v, tenantData: {...v.tenantData, [splt(field,1)]: val}};
      }
      return {...v, user: {...v.user, [splt(field,1)]: val}};
    }, {user: {}, property: {}, tenantData : {}}));
  }

  const nextPage = async () => {
    let fields = allPages[activePage].fields, nextPageNum = activePage+1,
    nextPageFields = allPages[nextPageNum].fields;
    console.log(fields, values, isEmpty(fields), noErrors(fields))
    if (isEmpty(fields)){
      console.log(`hee`)
      validateFields(fields);
      setFieldsTouched(fields, true);
    }
    else if (noErrors(fields)){
      setFieldsTouched(nextPageFields, false);
      setPageToCompleteAndGoTo(activePage, nextPageNum);
      if (fields.includes('property.roommates')){
        setFieldValue('property.roommateDetails', [...Array(values.property.roommates)].map(_ => ({
          name: ''
        })))
      }
    }
  }

  const backPage = () => {
    setActivePage(activePage-1);
  }

  let isFirstPage = () => activePage === 0;
  let isLastPage = () => activePage === (allPages.length - 1);

  return (
    <Flex>
      {!isEnum && <ProgressSidebar />}
      <Flex flex={7} minHeight={'100vh'} marginLeft={['0%', isEnum ? '0%' : '23%']}>
        <VStack alignItems={'initial'} padding={'3%'} gap={'0'} minWidth={'100%'}>
          {isEnum && (
            <BackButton visibility={isFirstPage() ? 'hidden' : 'visible'} onClick={backPage}>
              <ArrowBackIcon/> Back
            </BackButton>
          )}
          <EnumFlex isEnum={isEnum}>
            <Flex display={['flex', 'none']}>
              Page: {activePage+1}/{allPages.length}
            </Flex>
            <CurrentPage />
            {!isEnum && (
              <>
                <Spacer my={6} />
                <Flex justifyContent={'space-between'} alignItems={'flex-end'} marginTop={'auto'}>
                {isFirstPage() ? (<></>) : (
                  <SecondaryButton onClick={backPage}>Back</SecondaryButton>
                )}
                {isLastPage() ? (<></>) : (
                  <NextButton onClick={nextPage}>Next</NextButton>
                )}
                </Flex>
              </>
            )}
          </EnumFlex>
          
         
        </VStack>
      </Flex>
    </Flex>
  );
}

export const SignupForm = () => {
  let [status, setStatus] = useState<number|''>('');

  let register = useRegister({
    onError: (error: any) => {
      useNotifications.getState().addNotification({
        type: 'error',
        title: 'Error',
        message: error.message ? error.message : 'Failed to signup',
      });
    },
    onSuccess: () => {
      navigate('/app/profile');
    }
  });
  let create_property = useCreateProperty();
  let navigate = useNavigate();

  const onSubmit = async (val: any, { setSubmitting }: FormikHelpers<any>) => {
    if (status === StatusEnum.Renter){
      await register.mutateAsync(val.user);
      await create_property.mutateAsync(val.property);
      setSubmitting(true);
    }
    if (status === StatusEnum.Tenant){
      console.log(val)
      await register.mutateAsync({...val.user, ...val.tenantData});
      setSubmitting(true);
    }
  }
  
  if (status){
    return(
      <Formik
      initialValues={initialValuesSignup(status)}
      validationSchema={RegisterSchema(status)}
      onSubmit={onSubmit}
      >
        <Form>
          <DisplayPages />
        </Form>
      </Formik>
    );
  }
  else{
    return(
      <PageZero setStatus={setStatus} />
    )
  }
}

export * from './Pages';
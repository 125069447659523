export const SignupCategory = {
  TENANT: {
    value: 'tenant',
    offset: 5
  },
  RENTER: {
    value: 'renter',
    offset: 1
  }
};

// time for which a notification is displayed (in secs)
export const notif_time = 5;
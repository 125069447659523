import { PropertyAddValues, SignupFormValuesRenter, SignupFormValuesTenant, TenantFormValues } from "types";
import { SignupFormBaseValues } from "types";
import { StatusEnum } from "utils";

const baseUser = (status: number|''): SignupFormBaseValues => ({
  status,

  profilepic: null,
  name: '',
  age: '',
  language: '',
  location: '',
  gender: '',

  email: '',
  password: '',
  confirmPassword: ''
})

const baseProperty: PropertyAddValues = {
  location: '',
  rent: '',
  securityDeposit: '',
  roomSize: '',
  roommates: '',

  gender: '',
  age: 40,

  moveIn: '',
  moveInDate: '',
  moveOut: '',
  moveOutDate: '',
  contract: '',

  roommateDetails: [],

  roomPhotos: null,

  extraDetails: [],
  bus_by: '',
  cc_by: '',
  uni_by: '',
  bus_mins: '',
  cc_mins: '',
  uni_mins: '',
};

const baseTenant: TenantFormValues = {
  smoke: '',
  cleanliness: '',
  pets: '',
  socialize: '',
  cook: '',
  budget: '',
  city: '',
  moveIn: '',
  moveInDate: '',
  moveOut: '',
  moveOutDate: '',
  occupation: '',
  phoneNumber: '',
  photoHighlights: null,
  descriptions: []
};

export const initialValuesSignup = (s: number|''): SignupFormValuesRenter | SignupFormValuesTenant => {
  if (s === StatusEnum.Renter){
    return {
      user: baseUser(StatusEnum.Renter),
      property: baseProperty
    };
  }
  else if (s === StatusEnum.Tenant){
    return {
      user: baseUser(StatusEnum.Tenant),
      tenantData: baseTenant
    }
  }
  else{
    throw new Error('Failed');
  }
};
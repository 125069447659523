// import * as React from 'react';
import { primaryColorScheme } from 'utils';
import { styled } from 'styles';
import { Button as AButton } from '@chakra-ui/react';

export const Button = styled(AButton)``;
export const PrimaryButton = styled(Button)``;
PrimaryButton.defaultProps = {
  colorScheme: primaryColorScheme,
};

export const SecondaryButton = styled(Button)``;
SecondaryButton.defaultProps = {
  padding: '1.3rem 3rem',
  colorScheme: 'white',
  color: 'black.800',
  border: '1px solid #D0D5DD',
  boxShadow: '0px 2px 4px -2px rgba(16, 24, 40, 0.06)'
};

export * from './NextButton';
export * from './EnumOptionButton';
export * from './BackButton';
export * from './FilterButton';
export * from './ReviewButton';
export * from './GoogleButton';
export * from './googleWrapper';
export enum GenderEnum {
  "Male" = 1,
  "Female" = 2,
  "Any" = 3,
};
export enum StatusEnum {
  "Renter" = 1,
  "Tenant" = 2,
};
export enum Smoke {
  "Yes" = 1,
  "No" = 2,
  "Sometimes" = 3
}
export enum Cleanliness {
  "I like to keep it very clean" = 1,
  "Moderately clean is fine" = 2,
  "It doesn’t bother me" = 3
}
export enum Pets {
  "I have a pet" = 1,
  "I don't have a pet" = 2,
  "No pets please" = 3,
  "We can talk about it" = 4
}
export enum Socialize {
  "All the time" = 1,
  "Sometimes, but I value my privacy" = 2,
  "Just a little bit" = 3,
  "I don't want to socialize at all" = 4
}
export enum Cook {
  "Everyday" = 1,
  "Couple of time a week" = 2,
  "Rarely" = 3
}
export enum Occupation {
"Masters Student" = 1,
"Student" = 2,
"Working (full-time)" = 3,
"Working (part-time)" = 4,
"Self-Employed" = 5,
"Freelancer" = 6,
"Retired" = 7,
"Unemployed" = 8,
"Intern" = 9,
"Trainee" = 10
}
import { useRef } from 'react';
import { Body2, ErrorText, Heading1, RadioField, InputField } from 'components/molecules';
import { Grid, GridItem, Image, Spacer,FormLabel } from '@chakra-ui/react';
import { FieldArray, FormikValues, useFormikContext } from 'formik';
import { Select } from 'chakra-react-select'; // Import Select from chakra-react-select

import defaultPfp from 'assets/images/defaultPfp.png';

// TODO Roommate detail into a separate component
// TODO Use image input field

const RoommateDetail = ({i}: {i: number}) => {
  let { values, setFieldValue, errors, touched }: FormikValues = useFormikContext();
  let imgInput = useRef<HTMLInputElement|null>(null);
  
  const fileChange = (e: any) => {
    if (e.target.files.length > 0){
      setFieldValue(`property.roommateDetails[${i}].pfp`, e.target.files[0]);
    }
  }

  // List of languages for multi-select
  const languages = [
    { label: "German", value: "de" },
    { label: "English", value: "en" },
    { label: "French", value: "fr" },
    { label: "Spanish", value: "es" },
    { label: "Italian", value: "it" },
    { label: "Chinese", value: "zh" },
    { label: "Japanese", value: "ja" },
    { label: "Korean", value: "ko" },
    { label: "Hindi", value: "hi" },
    { label: "Russian", value: "ru" },
    { label: "Turkish", value: "tr" },
    { label: "Arabic", value: "ar" },
    { label: "Polish", value: "pl" },
    { label: "Portuguese", value: "pt" },
    { label: "Greek", value: "el" },
    { label: "Dutch", value: "nl" },
    { label: "Persian", value: "fa" },
    { label: "Vietnamese", value: "vi" },
    { label: "Serbian", value: "sr" },
    { label: "Croatian", value: "hr" },
    { label: "Bosnian", value: "bs" },
    { label: "Urdu", value: "ur" },
    { label: "Tamil", value: "ta" },
    { label: "Albanian", value: "sq" },
    { label: "Kurdish", value: "ku" },
    { label: "Danish", value: "da" },
    { label: "Romanian", value: "ro" },
    { label: "Hebrew", value: "he" },
    { label: "Bulgarian", value: "bg" },
    { label: "Czech", value: "cs" },
    { label: "Slovak", value: "sk" },
    { label: "Latvian", value: "lv" },
    { label: "Lithuanian", value: "lt" },
  ];


  const photoUploaded = () => (values.property.roommateDetails && values.property.roommateDetails[i]?.pfp);
  const getObjURL = () => URL.createObjectURL(values.property.roommateDetails[i]?.pfp);
  const hasError = () => (
    ( 
      errors.property.roommateDetails && 
      errors.property.roommateDetails[i]?.pfp
    )
      && 
    ( touched.property.roommateDetails &&
      touched.property.roommateDetails[i]?.pfp
    )
  );
  return(
    <Grid 
      templateRows='repeat(1, 1fr)'
      templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
      gap={4}
    >
      <GridItem colSpan={[1, 2]} rowSpan={1} >
        <Body2>Roommate # {i+1}</Body2>
      </GridItem>
      <GridItem colSpan={[1,2]} rowSpan={1} >
        <Image 
        src={photoUploaded() ? getObjURL() : defaultPfp} 
        borderRadius={photoUploaded() ? 'full' : 'initial'}
        cursor={'pointer'}
        width={'125px'}
        height={'125px'}
        objectFit={'cover'}
        onClick={() => imgInput?.current ? imgInput.current?.click() : ''}
        />
        {hasError() ? (<ErrorText>{errors.property.roommateDetails[i]?.pfp}</ErrorText>) : (<></>)}
        <input ref={imgInput} onChange={fileChange} 
        style={{display: 'none'}} type='file' accept='image/png,image/jpeg,image/jpg' />
      </GridItem>
      <GridItem colSpan={1} height={'fit-content'}>
        <InputField 
        name={`property.roommateDetails[${i}].name`}
        isRequired={true}
        label='Full Name'
        placeholder='Jonas'
        value={values.property.roommateDetails[i].name}
        />
      </GridItem>
      <GridItem colSpan={1} height={'fit-content'}>
        <InputField 
        name={`property.roommateDetails[${i}].age`}
        isRequired={false}
        label='Age'
        type="number"
        placeholder='25'
        value={values.property.roommateDetails[i].age}
        />
      </GridItem>
      <GridItem colSpan={1}>
        <FormLabel  htmlFor="user.language" textStyle="medium"> I speak</FormLabel>

          <Select
            name="user.language"
            options={languages}
            placeholder="Select languages"
            isMulti
            closeMenuOnSelect={false}
            selectedOptionStyle="check" // Optional: Highlight selected items
            value={languages.filter((lang) =>
              values.user.language?.includes(lang.value)
            )}
            onChange={(selected) => {
              const selectedValues = selected.map((option) => option.value);
              console.log(selectedValues.join(','))
              setFieldValue("user.language", selectedValues.join(','));
            }}
          />
        </GridItem>
      <GridItem colSpan={1}>
      <RadioField 
        isRequired={false}
        label='Gender'
        options={{1: 'Male', 2: 'Female', 3: 'Other'}}
        name={`property.roommateDetails[${i}].gender`}
        value={values.property?.roommateDetails[i].gender}
        />
      </GridItem>
      <Spacer flex={0} my={3} />
    </Grid>
  );
}


export const RoommateDetails = (): JSX.Element => {
  let { values, errors }: FormikValues = useFormikContext();


  return(
    <>
      <Heading1>Roommate Details</Heading1>
      <Spacer flex={0} my={3} />
      <FieldArray 
      name="roommateDetails"
      render={arrayHelpers => (
        [...Array(values.property?.roommates)].map((e, i) => (
          <RoommateDetail 
          key={`roommate-data-${i}`}
          i={i}
          />
        ))
      )}
      />
      {typeof errors.property?.roommateDetails === 'string' ? <ErrorText>{errors.property.roommateDetails}</ErrorText> : null}
    </>
  )
}
import React, { Fragment, useContext } from 'react';
import {
  Box,
  Flex,
  Image,
  Stack,
  HStack,
  Divider,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Avatar,
  theme,
} from '@chakra-ui/react';
import { PrimaryButton } from '../../molecules';
import logoImg from 'assets/images/logo.png';

import { Link as RLink, useLocation } from 'react-router-dom';
import { useLogout, useUser } from 'lib';
import { LogoWithName } from '../logoWithName';

import { StatusEnum } from 'utils';

// @ts-ignore
const getPostLoginMenuItems = (status: number) => ([
  {
    label: status === StatusEnum.Tenant ? "Find Apartments" : "Find Tenants",
    link: `/app`,
    props: {display: ["flex", "flex", "none"]},
    tabMenu: true
  }, {
    label: status === StatusEnum.Tenant ? "My Applications" : "Room Applications",
    link: `/app/applications`,
    props: {display: ["flex", "flex", "none"]},
    tabMenu: true
  }, {
    label: "Chat",
    link: `/app/chat`,
    props: {display: ["flex", "flex",  "none"]},
    tabMenu: true
  },  {
    label: "My Profile",
    link: `/app/profile`,
    props: {display: ["flex", "flex", "flex"]}
  },
]);

interface MenuOptionsType {
  label: string;
  link: string;
  props: {[s: string]: string[]};
  tabMenu?: boolean;
}

export const BrandLogo = (props: { onClick: React.MouseEventHandler<HTMLImageElement>; alt: string; }): JSX.Element => (
  <Box cursor="pointer">
    <Image
      height="24px"
      onClick={props.onClick}
      src={logoImg}
      alt={props.alt}
    />
  </Box>
);

// export const JobLink = (props: { name: string, locale: string }): JSX.Element => (
//   <PrimaryLink textDecoration='none !important' href={`${publicRuntimeConfig.lincCareer}/${props.locale}/jobs`}>
//     <Body0>{props.name}</Body0>
//   </PrimaryLink>
// );

export const MenuListItems = (props: { menuItems: MenuOptionsType[]; signOut: React.MouseEventHandler<HTMLButtonElement>; signOutLabel: string; }): JSX.Element => (
  <MenuList zIndex={10}>
    {
      props.menuItems.map(({ label, link, props: p }: MenuOptionsType, inx: number) => (
        <MenuItem as={RLink} to={link} {...p} key={label + inx}>
          {/* <PrimaryLink href={link}> */}
            {label}
          {/* </PrimaryLink> */}
        </MenuItem>
      ))
    }
    <MenuItem onClick={props.signOut}>{props.signOutLabel}</MenuItem>
  </MenuList>
);

export const PostLoginMenu = (props: { photoURL: string; displayName: string; menuItems: MenuOptionsType[]; signOutLabel: string; signOut: any }): JSX.Element => (
  <Menu>
    <Flex justifyContent='flex-end'>
        
      <MenuButton
        as={Button}
        size="sm"
        variant="link"
        mx='2'
        // rightIcon={<TriangleDownIcon />}
      >
        <Avatar src={`${process.env.REACT_APP_BASE_URL}/${props.photoURL}`} size='md' />
      </MenuButton>
    </Flex>
    <MenuListItems
      menuItems={props.menuItems}
      signOutLabel={props.signOutLabel}
      signOut={props.signOut}
    />
  </Menu>
);

const TabMenu = ({pathname, menuItems}: {pathname: string, menuItems: MenuOptionsType[];}) => {
  return(
    <>
    {menuItems.filter(i => i.tabMenu).map(({label, link}, inx: number) => (
      <Box 
      key={inx + label}
      display={["none", "none", "flex"]} 
      height="100%" 
      _hover={{borderBottom: `1px solid ${theme.colors.green[900]}`}} 
      borderBottom={`${(pathname === link || (pathname.startsWith('/app/chat') && link.startsWith('/app/chat'))) ? '1px' : '0px'} solid ${theme.colors.green[900]}`}>
        <PrimaryButton as={RLink} to={link} size="md" variant="nonae" height="100%">
          {label}
        </PrimaryButton>
      </Box>
    ))}
    </>
  );
}

export const Header: React.FC = () => {
  const { pathname } = useLocation();

  const { data } = useUser();

  const menuItems = getPostLoginMenuItems(data ? data?.status : 0);

  const logout = useLogout();
  const signOut = () => logout.mutate({});

  const displayName: string = data ? data['name'] : '';
  const photoURL: string = data ? data['profilepic'] : '';

  if (data){

    return (
      <Fragment>
        <Flex width={'100%'} justifyContent="space-between" px={8}>
          <Stack spacing={[1, 8]} py={2} direction={['column', 'row']} alignItems='center'>
            <LogoWithName size='45px' />
          </Stack>
          {/* Desktop view */}
          <Fragment>
            <HStack spacing={[0, 0, 4]} display={["none", "none", "inherit"]}>
              <TabMenu menuItems={menuItems} pathname={pathname} />
            </HStack>
          </Fragment>

          {/* Mobile view  */}
          <HStack minW={40} spacing={4} py={2}>
            <Box width="100%">
              <PostLoginMenu
                displayName={displayName}
                photoURL={photoURL}
                menuItems={menuItems}
                signOutLabel={'Logout'}
                signOut={signOut}
              />
            </Box>
          </HStack>
        </Flex>
        <Divider orientation="horizontal" />
      </Fragment>
    );
  }
  else{return(<></>)}
};


export const createFakeGoogleWrapper = (handleCredentialResponse: any) => {
  const googleLoginWrapper = document.createElement("div");
  // Or you can simple hide it in CSS rule for custom-google-button
  googleLoginWrapper.style.display = "none";
  googleLoginWrapper.classList.add("custom-google-button");

  // Add the wrapper to body
  document.body.appendChild(googleLoginWrapper);
  
  // Use GSI javascript api to render the button inside our wrapper
  // You can ignore the properties because this button will not appear
  // @ts-ignore
  window.google?.accounts.id.initialize({
    client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    callback: handleCredentialResponse
  });

  // @ts-ignore
  window.google.accounts.id.renderButton(googleLoginWrapper, {
    type: "icon",
    width: "200",
  });

  const googleLoginWrapperButton =
    googleLoginWrapper.querySelector("div[role=button]");

  return {
    click: () => {
      // @ts-ignore
      googleLoginWrapperButton.click();
    },
  };
};
import { Box, Image } from "@chakra-ui/react";

// button to like dislike or shortlist a property or tenant
export const ReviewButton = (
  {icon, bgColor, padding, onClick = () => {}
} : {icon: any, bgColor: string, padding?: string, onClick?: () => void}) => {
  return(
    <Box 
    cursor={'pointer'} 
    marginRight={2} 
    borderRadius={'50%'} 
    backgroundColor={bgColor} 
    padding={padding ? padding : '2'}
    onClick={onClick}
    >
      <Image src={icon} />
    </Box>
  );
}
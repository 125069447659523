import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "lib";
import { useNotifications } from "components/organisms";

export const useSwipeTenant = () => {
  const qC = useQueryClient()

  const shortlistMutation = useMutation({
    mutationKey: ['shortlistTenant'],
    mutationFn: (tenantId: string) => {
      return api.post('/tenant/shortlist', {body: JSON.stringify({tenantId})}, true, 'json');
    },
    onError: (error) => {
      useNotifications.getState().addNotification({
        type: 'error',
        title: 'Failed to shortlist tenant',
        message: error.message ? error.message : 'Try again',
      });
    },
    onSuccess: (data) => {
      if (data.msg){
        useNotifications.getState().addNotification({
          type: 'info',
          title: 'Failed to shortlist tenant',
          message: data.msg
        });  
      }
      else{
        qC.invalidateQueries({queryKey: ['getShortlistTenants']})
      }
    }
  })

  const acceptMutation = useMutation({
    mutationKey: ['acceptTenant'],
    mutationFn: (tenantId: string) => {
      return api.post('/tenant/accept', {body: JSON.stringify({tenantId})}, true, 'json');
    },
    onError: (error) => {
      useNotifications.getState().addNotification({
        type: 'error',
        title: 'Failed to swipe right tenant',
        message: error.message ? error.message : 'Try again',
      });
    },
    onSuccess: (data) => {
      if (data.msg){
        useNotifications.getState().addNotification({
          type: 'info',
          title: 'Failed to swipe right tenant',
          message: data.msg
        });  
      }
      else{
        qC.invalidateQueries({queryKey: ['getPendingTenants']})
      }
    }
  })

  const rejectMutation = useMutation({
    mutationKey: ['rejectTenant'],
    mutationFn: (tenantId: string) => {
      return api.post('/tenant/reject', {body: JSON.stringify({tenantId})}, true, 'json');
    },
    onError: (error) => {
      useNotifications.getState().addNotification({
        type: 'error',
        title: 'Failed to swipe left tenant',
        message: error.message ? error.message : 'Try again',
      });
    },
    onSuccess: (data) => {
      if (data.msg){
        useNotifications.getState().addNotification({
          type: 'info',
          title: 'Failed to swipe left tenant',
          message: data.msg
        });
      }
      else{
        qC.invalidateQueries({queryKey: ['getPendingTenants']})
      }
    }
  })
  return { shortlistMutation, acceptMutation, rejectMutation };
}
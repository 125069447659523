import React, { FC, InputHTMLAttributes, useEffect, useState } from 'react';
import { useField, useFormikContext } from 'formik';
import {
  //
  FormControl,
  FormErrorMessage,
  InputProps,
  forwardRef,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { CalendarIcon } from '@chakra-ui/icons';
import { default as DPicker, DatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { Input, FormLabel } from 'components/atoms';
// import { currentDate } from './../../../../utils';

// interface DatePickerFieldProps {
//   onChange: (dates: any) => void;
//   startDate: string | null;
//   endDate: string | null;
//   id?: string;
//   value?: Date;
//   closeOnScroll?: any;
//   adjustDateOnChange?: boolean;
// }

const inputChangedHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
  const updated = event.target.value;
  return updated;
};

const dateFormat = 'yyyy-MM-dd';
const CustomInput = forwardRef(({ value, onClick, disabled }, ref) => (
  <InputGroup>
    <Input
      value={value}
      onChange={(event) => inputChangedHandler(event)}
      placeholder={dateFormat.toUpperCase()}
      minW="160px"
      onClick={onClick}
      ref={ref}
      isDisabled={disabled}
    />
    <InputRightElement pointerEvents="none" color="gray.300" fontSize="1.2em">
      <CalendarIcon />
    </InputRightElement>
  </InputGroup>
));

const DatePicker: React.FC<DatePickerProps> = ({
  onChange = () => {},
  value,
  ...rest
}) => {
  const [startDate, setStartDate] = useState<Date>();
  useEffect(() => {
    if(value) {
      setStartDate(new Date(value));
    }
  }, [value]);

  return (
    <div>
      <DPicker
        dateFormat={dateFormat}
        placeholderText={dateFormat}
        customInput={<CustomInput />}
        selected={startDate}
        onChange={(date: any, event: any) => {
          const formatDate = new Date(date.toString());
          setStartDate(formatDate);
          onChange(date, event);
        }}
        {...rest}
      />
    </div>
  );
};

type InputFieldProps = InputHTMLAttributes<HTMLInputElement> & {
  name: string;
  label?: string;
  isRequired?: boolean;
  // eslint-disable-next-line
  // setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  showMonthDropdown?: boolean;
  showYearDropdown?: boolean;
  showDisabledMonthNavigation?: boolean;
  // dropdownMode?: string;
  maxDate?: Date;
  minDate?: Date;
};

export const InputDateField: FC<DatePickerProps & InputFieldProps & InputProps> = ({
  label,
  minDate,
  isRequired = false,
  isDisabled = false,
  // setFieldValue,
  ...props
}) => {
  let { setFieldValue } = useFormikContext();
  const [field, { touched, error }] = useField(props);
  return (
    <FormControl isInvalid={!!error} isRequired={isRequired}>
      {label && <FormLabel htmlFor={field.name}>{label}</FormLabel>}
      <DatePicker
        adjustDateOnChange
        onChange={(date) => {
          setFieldValue(field.name, date?.getTime());
        }}
        id={field.name}
        closeOnScroll={(e) => e.target === document}
        disabled={isDisabled}
        value={field.value}
        minDate={minDate}
        // {...field}
        // {...props}
      />
      { touched && error ? <FormErrorMessage>{error}</FormErrorMessage> : null}
    </FormControl>
  );
};

// export const InputDOBDateField: FC<DatePickerProps & InputFieldProps & InputProps> = ({
//   // label,
//   // isRequired = false,
//   setFieldValue,
//   ...props
// }) => {
//   // const [field, { error }] = useField(props);
  
//   return (
//     <InputDateField
//       showMonthDropdown
//       showYearDropdown
//       dropdownMode="select"
//       showDisabledMonthNavigation
//       // maxDate={moment().subtract(ADULT_AGE_LIMIT, 'years').toDate()}
//       // maxDate={new Date()}
//       setFieldValue={setFieldValue}
//       {...props}
//     />
//   );
// };

import { Tab, TabIndicator, TabList } from "@chakra-ui/react";
import { Body1 } from "components/molecules";

export const ApplicationTabs = ({ data }: { data: string }) => (
  <TabList width={"fit-content"} padding={2} borderRadius={'50px'} zIndex={1} bgColor={'rgba(247, 247, 247, 1)'}>
    <Tab zIndex={3}><Body1>Accepted {data}</Body1></Tab>
    <Tab zIndex={3}><Body1>Pending {data}</Body1></Tab>
    <Tab zIndex={3}><Body1>Shortlisted {data}</Body1></Tab>
    <TabIndicator 
      zIndex={2} 
      boxShadow={'0px 6px 17px 0px rgba(0, 0, 0, 0.08)'} 
      height={['65px', '40px']} 
      bg='white' 
      borderRadius='50px' 
    />
  </TabList>
)
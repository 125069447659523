import { SecondaryButton } from ".";
import googleIcon from 'assets/images/g-normal.png';

export const GoogleSignin = ({onClick}: any) => {
  return(
    <SecondaryButton onClick={onClick} width={["100%", "120%"]}>
      <img src={googleIcon} />
      <span>Sign in with google</span>
    </SecondaryButton>
  );
}

export const GoogleSignup = ({onClick}: any) => {
  return(
    <SecondaryButton onClick={onClick} width={["100%", "120%"]}>
      <img src={googleIcon} />
      <span>Sign up with google</span>
    </SecondaryButton>
  );
}
import { Flex } from '@chakra-ui/react';
import { Notification } from './notification';
import { useNotifications } from './notifications-store';
import 'animate.css';

export const Notifications = () => {
  const { notifications, dismissNotification } = useNotifications();

  return (
    <Flex  className='animate__animated animate__bounce'
    style={{"display":"flex","position":"fixed","top":"0","right":"0","bottom":"0","left":"0","zIndex":50,"paddingLeft":"1rem","paddingRight":"1rem","paddingBottom":"1.5rem","flexDirection":"column","alignItems":"flex-end","pointerEvents":"none",
      // "@media (min-width: 640px)":{"padding":"1.5rem","alignItems":"flex-start"}
    }}
    pointerEvents={'none'} position={'fixed'} inset={0} zIndex={50} marginTop={'1rem'} flexDir={'column'} alignItems={'end'} p={{md: '4 6', sm: '6' }}>
       {notifications.map((notification) => (
         <Notification
           key={notification.id}
           notification={notification}
           onDismiss={dismissNotification}
         />
       ))}

    </Flex>
    // <div
    //   aria-live="assertive"
    //   className="pointer-events-none fixed inset-0 z-50 flex flex-col items-end space-y-4 px-4 py-6 sm:items-start sm:p-6"
    //   className="flex-col items-end space-y-4 sm:items-start sm:p-6"
    // >
    // </div>
  );
};
import { Spacer } from '@chakra-ui/react';
import { FormikValues, useFormikContext } from 'formik';
import { Body0, Heading1, InputField, OptionGroup } from 'components/molecules';
import { useCurrentPage } from 'lib';

export const ExtraDetails = (): JSX.Element => {
  let { values, setFieldValue }: FormikValues = useFormikContext();
  let { options, fields } = useCurrentPage();
  
  const saveField = (items: string[]) => {
    setFieldValue(fields[0], items);
  }

  return(
    <>
      <Heading1>Amenities and other details</Heading1>
      <Spacer flex={0} my={3} />
      <OptionGroup 
      selected={values.property.extraDetails}
      options={options ? options : []}
      columns={[2, 5]}
      onChange={saveField}
      />
      <Spacer flex={0} my={4} />
      <Heading1>What's nearby?</Heading1>
      <Spacer flex={0} my={3} />
      <Body0 marginBottom={5}>
        <span>The bus stop is</span> 
        <InputField name="property.bus_mins" value={values.property.bus_mins} isRequired={false} type='number' controlStyles={{width: ['8%', '3%'], mx: 1, display: 'inline-block'}} placeholder='5' padding={'6px'}  /> 
        <span>mins away  </span>
        {/* <InputField name="property.bus_by" value={values.property.bus_by} type="text" isRequired={false} controlStyles={{width: '5%', mx: 1, display: 'inline-block'}} padding={'6px'} placeholder='Foot'/> */}
      </Body0>
      <Body0 marginBottom={5}>
        <span>The University is</span>
        <InputField name="property.uni_mins" value={values.property.uni_mins} isRequired={false} type='number' controlStyles={{width: ['8%', '3%'], mx: 1, display: 'inline-block'}} placeholder='5' padding={'6px'}  /> 
        <span>mins away </span>
        {/* <InputField name="property.uni_by" value={values.property.uni_by} type="text" isRequired={false} controlStyles={{width: '5%', mx: 1, display: 'inline-block'}} padding={'6px'} placeholder='Foot'/> */}
      </Body0>
      <Body0>
        <span>The City Centre is</span>
        <InputField name="property.cc_mins" value={values.property.cc_mins} isRequired={false} type='number' controlStyles={{width: ['8%', '3%'], mx: 1, display: 'inline-block'}} placeholder='5' padding={'6px'}  /> 
        <span>mins away </span>
        {/* <InputField name="property.cc_by" value={values.property.cc_by} type="text" isRequired={false} controlStyles={{width: '5%', mx: 1, display: 'inline-block'}} padding={'6px'} placeholder='Foot'/> */}
      </Body0>
    </>
  )
}
import { Dispatch, SetStateAction } from "react";
import { createContext } from "react";

export interface ActivePageContextInterface {
  activePage: number;
  setActivePage: Dispatch<SetStateAction<number>>;
}

const defaultState = {
  activePage: 0,
  setActivePage: (activepage: number) => {},
} as ActivePageContextInterface;

export const ActivePageContext = createContext(defaultState);

import { Spacer } from '@chakra-ui/react';
import { FormikValues, useFormikContext } from 'formik';

import { Heading1, RadioField, InputDateField } from 'components/molecules';

export const MoveInDetails = (): JSX.Element => {
  let { values }: FormikValues = useFormikContext();

  return(
    <>
      <Heading1>When can the tenant move in?</Heading1>
      <Spacer my={4} flex={0} />
      <RadioField 
      isRequired={true}
      label='Preferred move-in date?'
      options={{'now': 'Right Away', 'later': 'Later'}}
      value={values.property.moveIn}
      name='property.moveIn'
      />
      {values.property.moveIn === 'later' && <InputDateField 
      isRequired={true}
      isDisabled={values.property.moveIn === 'now'}
      label={'Date'}
      value={values.property.moveInDate}
      minDate={new Date()}
      name="property.moveInDate"
      />}
      <Spacer my={5} flex={0} />
      <RadioField 
      isRequired={true}
      label='For how long is the apartment available?'
      options={{'now': 'Unlimited, i’m leaving the apartment', 'later': 'Select date'}}
      value={values.property.moveOut}
      name='property.moveOut'
      />
      {values.property.moveOut === 'later' && <InputDateField 
      isRequired={true}
      isDisabled={values.property.moveOut === 'now'}
      label={'Date'}
      name="property.moveOutDate"
      minDate={values.property.moveInDate ? new Date(values.property.moveInDate) : new Date()}
      value={values.property.moveOutDate}
      />}
      <Spacer my={5} flex={0} />
      <RadioField 
      isRequired={true}
      label='Is the apartment available with contract or without?'
      options={{0: 'With Contract', 1: 'Without Contract'}}
      value={values.property.contract}
      name='property.contract'
      />
    </>
  )
}
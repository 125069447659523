import { SelectField } from "components/molecules";
import { enumToObject, Occupation } from "utils";
import { FormikValues, useFormikContext } from "formik";

export const EditOccupation = () => {
  const { values, setFieldValue }: FormikValues = useFormikContext();

  return(
      <SelectField
        name="tenantData.occupation"
        label="Occupational Status"
        isRequired={true}
        options={enumToObject(Occupation)}
        chakraProps={{
          placeholder: values.tenantData.occupation
            ? Occupation[values.tenantData.occupation]
            : "-",
        }}
      />
  );
}
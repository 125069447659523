import { Flex } from '@chakra-ui/react';
import { ReviewButton } from 'components/molecules';
import ThumbsUp from 'assets/images/ThumbsUp.svg';
import ThumbsDown from 'assets/images/ThumbsDown.svg';
import Heart from 'assets/images/Heart.svg';

export const ReviewButtons = ({onClick}: {onClick: (arg: "accept"|"reject"|"shortlist") => void}) => {
  return(
    <Flex style={{
      position: 'absolute',
      bottom: '25px',
      zIndex: 1000,
      left: '50%',
      transform: 'translateX(-50%)'
    }}>
      <ReviewButton
      onClick={() => onClick('reject')}
      icon={ThumbsDown}
      bgColor="black"
      padding='4'
      />
      <ReviewButton
      icon={Heart}
      bgColor="green.500"
      padding='4'
      onClick={() => onClick('shortlist')}
      />
      <ReviewButton
      icon={ThumbsUp}
      bgColor="rgba(252, 64, 107, 1)"
      padding='4'
      onClick={() => onClick('accept')}
      />
    </Flex>
  );
}
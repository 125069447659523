import { useContext, useEffect, useRef, useState } from "react";
import { Flex } from "@chakra-ui/react";
import { SubHeader, ReviewButtons, ApartmentPost } from "components/organisms";
import { Spinner } from "components/atoms";
import { StatusEnum } from "utils";
import { useGetListings } from "lib";
import { useSwipeListing } from "lib/mutations/useSwipeListing";
import { DashboardListing } from "context";

export const ApartmentPosts = () => {
  // let [ page, setPage ] = useState<string>('');
  let [ rooms, setRooms ] = useState<any[]>([]);

  const { listingId: page, setListingId: setPage } = useContext(DashboardListing);

  let { isFetching, isError, data: roomData } = useGetListings(page);
  
  let {
    shortlistMutation,
    acceptMutation,
    rejectMutation
  } = useSwipeListing();
  
  useEffect(() => postRef?.current?.focus(), []);

  useEffect(() => {
    if (roomData){
      setRooms([roomData]);
    }
    else if (roomData === null){
      // setRooms([]);
      setPage('');
      alert('All Done');
    }
  }, [roomData]);

  //fetches the next item and display it
  const moveItem = (animation: "animate__zoomOutUp"|"animate__fadeOutRight"|"animate__fadeOutLeft") => {
    if (postRef.current){
      setTimeout(() => next(),200);
      postRef.current.classList.remove('animate__animated', 'animate__zoomIn');
      postRef.current.classList.add('animate__animated', animation);  
    }
  }

  const shortlistProperty = (listingId: string) => {
    shortlistMutation.mutate(listingId, {
      onSuccess: () => 
        moveItem("animate__zoomOutUp")
    });
  }
  const acceptProperty = (propertyId: string) => {
    acceptMutation.mutate(propertyId, {
      onSuccess: () => 
        moveItem("animate__fadeOutRight")
    });
  }
  const rejectProperty = (listingId: string) => {
    rejectMutation.mutate(listingId, {
      onSuccess: () => 
        moveItem("animate__fadeOutLeft")
    });
  }

  // called when any of 3 btn is clicked
  const nextItem = (dir: "accept"|"reject"|"shortlist") => {
    if (roomData){
      if (dir === "shortlist"){
        shortlistProperty(roomData._id)
      }
      if (dir === "accept"){
        acceptProperty(roomData._id)
      }
      if (dir === "reject"){
        rejectProperty(roomData._id)
      }
    }
  }

  const next = () => {
    if (roomData){
      setPage(roomData._id);  
    }
  }

  const postRef = useRef<HTMLDivElement|null>(null);
  
  return(
    <>
      {/* <SubHeader status={StatusEnum.Tenant} /> */}
      {isFetching ? 
      <Flex alignItems={"center"} justifyContent={'center'} height={'100%'} >
        <Spinner />
      </Flex>
      : <></>}
      {!isFetching && !isError ? (
        <>
          {rooms.map((listing, i) => (
            <ApartmentPost ref={postRef} listing={listing} />
          ))}
          {<ReviewButtons 
          onClick={(a) => {nextItem(a)}}
          />}
        </>
      ) : (<></>)}
    </>
  );
}
import { VStack } from "@chakra-ui/react";
import { Heading1Base } from "components/atoms";
import { EnumButton } from "components/molecules";
import { FormikValues, useFormikContext } from "formik";
import { IEnumType } from "types";
import { enumToObject, splt } from "utils";

export const EditEnum = ({enum_val, field}: {field: string, enum_val: IEnumType}) => {
  const { values, setFieldValue, fields }: FormikValues = useFormikContext();
  console.log(fields)
  return(
    <>
        {/* <Heading1Base margin={"20px"}>{sideText}</Heading1Base> */}
        <VStack spacing={4}>
          {Object.keys(enumToObject(enum_val)).map((en, i) => (
          <EnumButton
            key={`${i}-${en}`}
            textStyle="light" // Apply the predefined text style
            width="300px"
            bg={
              parseInt(values[splt(field, 0)][splt(field, 1)]) === parseInt(en)
                ? "green.600"
                : "none"
            }
            color={
              parseInt(values[splt(field, 0)][splt(field, 1)]) === parseInt(en)
                ? "white"
                : "black.800"
            }
            onClick={() => setFieldValue(field, en)}
          >
            {enum_val ? enum_val[en as any] : ""}
          </EnumButton>
          ))}
        </VStack>
    </>
  )
}
import { useState, useEffect } from "react";
import { Spacer, Text } from "@chakra-ui/react";
import { FormikValues, useFormikContext } from "formik";
import { Select } from "chakra-react-select";

import {
  InputDateField,
  Heading1,
  SliderComp,
  RadioField,
} from "components/molecules";

export const EditLocation = (): JSX.Element => {
  const { values, setFieldValue }: FormikValues = useFormikContext();

  const [cities, setCities] = useState<{ value: string; label: string }[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");

  // Fetch cities from Photon API
  const fetchCities = async (query: string) => {
    if (query.length < 3) {
      setCities([]);
      return;
    }
    const bbox = "5.866,47.270,15.042,55.058"; // Germany's approximate geographic bounding box: [minLon, minLat, maxLon, maxLat]

    const url = `https://photon.komoot.io/api/?q=${query}&lang=en&limit=10&bbox=${bbox}`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      const cityList = data.features
        .filter((item: any) =>
          item.properties.type === "city" && item.properties.osm_value !== "village"    && item.properties.osm_value !== "town"
        )
        .map((item: any) => ({
          value: item.properties.name,
          label: item.properties.name,
        }));
      setCities(cityList);
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  // Debounce fetch function to avoid multiple API calls
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (searchQuery.length >= 3) {
        fetchCities(searchQuery);
      }
    }, 500); // Adjust debounce time as needed
    return () => clearTimeout(timeoutId); // Cleanup on unmount or query change
  }, [searchQuery]);

  return (
    <>
      {/* City Dropdown */}
      <Text textStyle="medium" fontSize="md">
        Which city should the room be located in?
      </Text>
      <Spacer my={1} flex={0} />
      <Select
        name="tenantData.city"
        options={cities}
        placeholder="Start typing a city..."
        value={
          cities.find((city) => city.value === values.tenantData.city) || null
        }
        onInputChange={(inputValue) => setSearchQuery(inputValue)} // Update query
        onChange={(selectedOption) =>
          setFieldValue("tenantData.city", selectedOption?.value)
        }
        chakraStyles={{
          container: (provided) => ({
            ...provided,
            width: "100%",
          }),
        }}
        isClearable
        isSearchable
        noOptionsMessage={() =>
          searchQuery.length < 3
            ? "Type at least 3 characters to search"
            : "No cities found"
        }
      />
    </>
  );
};

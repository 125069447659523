import { FormikValues, useFormikContext } from 'formik';
import { Spacer } from '@chakra-ui/react';

import { Heading1, SliderComp, RadioField } from 'components/molecules';

export const IdealTenant = (): JSX.Element => {
  let { values }: FormikValues = useFormikContext();
  return(
    <>
    <Heading1>Describe Your Ideal Tenant</Heading1>
    <Spacer my={4} flex={0} />
    <RadioField 
    value={values.property.gender}
    isRequired={true}
    label='Gender'
    options={{1: 'Male', 2: 'Female', 3: 'Any'}}
    name='property.gender'
    />
    <Spacer my={3} flex={0} />
    <SliderComp 
    name='property.age'
    postfix='Years'
    label="Age" min={18} max={40} sliderValue={values.property.age}
    />
    </>
  )
}
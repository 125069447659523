import React, { FC, PropsWithChildren, SelectHTMLAttributes } from 'react';
import { useField } from 'formik';
import { FormControl, FormErrorMessage, SelectProps } from '@chakra-ui/react';
import { Select, FormLabel } from './../../../atoms/Form';

export type SelectFieldProps = SelectHTMLAttributes<HTMLSelectElement> & {
  name: string;
  label?: string;
  isRequired: boolean;
  isDisabled?: boolean;
  chakraProps?: SelectProps;
  size?: string;
  options: { [key: string]: string };
};

export const SelectField: FC<SelectFieldProps> = ({
  label,
  children,
  chakraProps = {},
  isRequired = false,
  isDisabled = false,
  // onChange = () => {},
  options,
  ...props
}: PropsWithChildren<SelectFieldProps>) => {
  const [
    field,
    meta
  ] = useField({ ...props, type: 'select' });

  return (
    <FormControl isInvalid={!!meta.error && !!meta.touched} isRequired={isRequired}>
      { label ? <FormLabel textStyle="medium" htmlFor={field.name}>{label}</FormLabel>: <></> }
      <Select
        isRequired={isRequired}
        isDisabled={isDisabled}
        {...field}
        {...props}
        {...chakraProps}
        // onChange={onChange}
        id={field.name}
      >
        {children}
        {Object.keys(options).map((key, ind) => (
            <option
              key={ind}
              value={key}
            >
              {options[key]}
            </option>
          ))}
      </Select>
      {(meta.error && meta.touched) ? <FormErrorMessage>{meta.error}</FormErrorMessage> : null}
    </FormControl>
  );
};

import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner } from "@chakra-ui/react";
import { PrimaryButton, SecondaryButton } from "components/molecules";
import { Form, Formik } from "formik";
import { Cleanliness, Cook, Pets, Smoke, Socialize, StatusEnum } from "utils";
import { RegisterSchema } from "validation";
import { EditCountry, EditEnum, EditLanguage, EditMoveInMoveOutDate, EditOccupation } from './EditForms'
import { TenantType } from "types";
import { useMutation } from "@tanstack/react-query";
import { useEditTenant } from "lib/mutations/useEditTenant";
import { EditLocation } from "./EditForms/EditLocation";
import { EditGender } from "./EditForms/EditGender";

type EditModalProps = {
  isOpen: boolean
  handleClose: () => void
  id: string
  user: TenantType
}

const editTypes: {[key: string]: any} = {
  'movein': {
    comp: EditMoveInMoveOutDate,
    header: `Edit Move In Date`,
    fieldsToSubmit: (values: any) => 
      ({tenantData: {moveInDate: values.tenantData.moveInDate, moveOutDate: values.tenantData.moveOutDate}})
  },
  'location': {
    comp: EditLocation,
    header: `Edit Location`,
    fieldsToSubmit: (values: any) => ({
      tenantData: {city: values.tenantData.city}
    })
  },
  'language': {
    comp: EditLanguage,
    header: `Edit Languages`,
    fieldsToSubmit: (values: any) => ({
      language: values.language
    })
  },
  'countries': {
    comp: EditCountry,
    header: `Edit Country`,
    fieldsToSubmit: (values: any) => ({
      location: values.location
    })
  },
  'occupation': {
    comp: EditOccupation,
    header: `Edit Occupation`,
    fieldsToSubmit: (values: any) => ({
      tenantData: {occupation: values.tenantData.occupation}
    })
  },
  'gender': {
    comp: EditGender,
    header: `Edit Gender`,
    fieldsToSubmit: (values: any) => ({
      gender: values.gender
    })
  },
  'smoke': {
    comp: () => <EditEnum enum_val={Smoke} field={'tenantData.smoke'} />,
    header: `Do you smoke?`,
    fieldsToSubmit: (values: any) => ({
      tenantData: {smoke: values.tenantData.smoke}
    })
  },
  'cleanliness': {
    comp: () => <EditEnum enum_val={Cleanliness} field={'tenantData.cleanliness'} />,
    header: `How clean do you prefer your home to be?`,
    fieldsToSubmit: (values: any) => ({
      tenantData: {cleanliness: values.tenantData.cleanliness}
    })
  },
  'pets': {
    comp: () => <EditEnum enum_val={Pets} field={'tenantData.pets'} />,
    header: `What about pets?`,
    fieldsToSubmit: (values: any) => ({
      tenantData: {pets: values.tenantData.pets}
    })
  },
  'cook': {
    comp: () => <EditEnum enum_val={Cook} field={'tenantData.cook'} />,
    header: `How often do you cook?`,
    fieldsToSubmit: (values: any) => ({
      tenantData: {cook: values.tenantData.cook}
    })
  },
  'socialize': {
    comp: () => <EditEnum enum_val={Socialize} field={'tenantData.socialize'} />,
    header: `How often would you like to socialize with your roommates?`,
    fieldsToSubmit: (values: any) => ({
      tenantData: {socialize: values.tenantData.socialize}
    })
  }
}

const BaseComp = () => (
  <div>Asdf</div>
)

export const EditModal = ({isOpen, handleClose, id, user} : EditModalProps) => {
  const {comp: EditFields, header, fieldsToSubmit} = id ? editTypes[id] : BaseComp;
  
  const mutation = useEditTenant()
  console.log(user)
  return(
    <Modal isOpen={isOpen} isCentered onClose={handleClose}>
      <ModalOverlay>
        <ModalContent>
          <Formik
            initialValues={user}
            validate={() => ({})}
            onSubmit={async (values, { setSubmitting }) => {
              try{
                console.log('...')
                await mutation.mutateAsync(
                  fieldsToSubmit(values),
                  {onSuccess: () => {
                    handleClose()
                  },
                  onError: (e: any) => {
                    console.log(e)
                  }}  
                );
              }
              catch(e){console.log(e)}
            }}
          >
          <Form>
            <ModalHeader>
              {header}
            </ModalHeader>
            <ModalBody>
                <EditFields/>
            </ModalBody>
            <ModalFooter>
              <PrimaryButton type='submit' isDisabled={mutation.isPending} width="100%" ml="2">
                {mutation.isPending ? <Spinner /> : 'Save'}
              </PrimaryButton>
              <SecondaryButton width="100%" ml="2" onClick={handleClose}>Cancel</SecondaryButton>
            </ModalFooter>
          </Form>
        </Formik>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
}

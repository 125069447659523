import { useRef } from 'react';
import { Grid, GridItem, Spacer } from '@chakra-ui/react';
import { FormikValues, useFormikContext } from 'formik';

import { ImageInputField, Heading1, RadioField, InputField } from 'components/molecules';


// TODO: into a common components
export const ProfileDetails = (): JSX.Element => {
  let { values, setFieldValue, errors, touched }: FormikValues = useFormikContext();
  let imgInput = useRef<HTMLInputElement|null>(null);

  const fileChange = (e: any) => {
    if (e.target.files.length > 0){
      setFieldValue('user.profilepic', e.target.files[0]);
    }
  }

  return(
    <>
      <Heading1>Tell Us About Yourself</Heading1>
      <Spacer flex={0} my={3} />
      <Grid 
        templateRows='repeat(1, 1fr)'
        templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
        gap={4}
      >
        <GridItem colSpan={[1,2]} rowSpan={1} >
          <ImageInputField 
          value={values.user?.profilepic}
          fileChange={fileChange}
          name='user.profilepic'
          />
        </GridItem>
        <GridItem colSpan={1} height={'fit-content'}>
          <InputField 
          name='user.name'
          value={values.user?.name}
          isRequired={true}
          label='Full Name'
          placeholder='Jonas'
          />
        </GridItem>
        <GridItem colSpan={1} height={'fit-content'}>
          <InputField 
          name='user.age'
          value={values.user?.age}
          isRequired={true}
          label='Age'
          type="number"
          placeholder='25'
          />
        </GridItem>
        <GridItem colSpan={1}>
          <InputField 
          name='user.language'
          value={values.user?.language}
          isRequired={true}
          label='I speak'
          placeholder='Deutsch'
          />
        </GridItem>
        <GridItem colSpan={1}>
          <InputField 
          name='user.location'
          value={values.user?.location}
          isRequired={true}
          label='Current Location'
          placeholder='Deutschland'
          />
        </GridItem>
        <GridItem colSpan={1}>
          <RadioField 
            isRequired={true}
            label='Gender'
            options={{1: 'Male', 2: 'Female', 3: 'Other'}}
            name='user.gender'
            value={values.user?.gender}
            />
        </GridItem>
      </Grid>
    </>
  )
}
import React from "react";
import { Box, Flex, Spacer, VStack } from "@chakra-ui/react";
import { Body0 } from "components/molecules";
import { LogoWithName } from "components/organisms";
import { useCurrentPage } from "lib";
import emailIcon from 'assets/images/email.svg';
import pageActive from 'assets/images/stepActive.svg';
import pageCompleted from 'assets/images/stepCompleted.svg';
import pageRemaining from 'assets/images/stepRemaining.svg';

// todo: make it a common component

export const ProgressSidebar = () => {
  let {allPages, activePageIndex} = useCurrentPage();

  const pagesNotToShow = allPages.filter(item => item.enum)

  // offset for renter, bcs before sidebar we only show one page
  // activkcePageIndex -= 1;           
  const isActive = (i: number) => ((activePageIndex - pagesNotToShow.length) === i)

  return(
    <Flex position={'fixed'} display={['none', 'flex']} width={'23%'} flex={2} backgroundColor={'green.500'} minHeight={'100vh'}>

    <VStack alignItems={'initial'} padding={'10%'} gap={'0'} minWidth={'100%'}>
      <Flex>
        <Box paddingLeft={'10%'}>
        <LogoWithName color="white" noName={false} size="40px" />
        </Box>
      </Flex>
      <Spacer my={6} flex={0} />
      <VStack alignItems={'initial'} gap={'0'}>
        {allPages.filter(item => !item.enum).map((item, i) => (
          <React.Fragment key={`progress-sidebar-${i}`}>
          <Box key={`${item.sideText}-${i}`} 
          // default
          display={'flex'} justifyContent={'space-between'} padding={'5% 10%'} borderBottom={'1px solid #FFFFFF33'} fontWeight={500}

          // active page
          borderRadius={isActive(i) ? '8px' : '0px'} color={isActive(i) ? 'green.600' : '#E8EAED'} 
          backgroundColor={isActive(i) ? 'white' : 'transparent'} 
          opacity={(isActive(i) || item?.completed) ? '100%' : '50%'} 
          // default                 
          >
            {item.sideText} 
            <img src={isActive(i) ? pageActive : (item?.completed ? pageCompleted : pageRemaining)} />
          </Box>
          <Spacer my={'2px'} flex={0} />
          </React.Fragment>
        ))}
      </VStack>
      <Spacer/>
      <Box display={'flex'}>
        <img src={emailIcon} style={{marginRight: '3px'}} />
        <Body0 color="white">
          hey@heywg.com
        </Body0>
      </Box>
    </VStack>
    </Flex>
  );
}
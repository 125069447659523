import { createContext, Dispatch, SetStateAction } from "react";

import { Description, EnumPage, GetNotified, AboutYou, RoomPreferences, ExtraDetails, Location, CompleteProfile, 
  ProfileDetails, PhotoHighlights, RoomPhotos, MoveInDetails, IdealTenant, RoommateDetails } from "components/organisms";

import { Cleanliness, Cook, Pets, Smoke, Socialize } from "utils";
import { IEnumType } from "types";

export interface PageType {
  sideText: string,
  Component: () => JSX.Element,
  completed: boolean,
  fields: Array<string>,
  enum?: boolean,
  enum_val?: IEnumType,
  options?: Array<string>,
  nextBtn?: true
}

export const RenterPages = [
  {
    sideText: 'Location',
    Component: () => <Location />,
    completed: false,
    fields: ['property.location', 'property.rent', 'property.securityDeposit', 'property.roomSize', 'property.roommates']
  },
  {
    sideText: 'Ideal Tenant',
    Component: () => <IdealTenant />,
    completed: false,
    fields: ['property.gender', 'property.age']
  },
  {
    sideText: 'Move in Details',
    Component: () => <MoveInDetails />,
    completed: false,
    fields: ['property.moveIn', 'property.moveInDate', 'property.moveOut', 'property.moveOutDate', 'property.contract']
  },
  {
    sideText: 'Roommate Details',
    Component: () => <RoommateDetails />,
    completed: false,
    fields: ['property.roommateDetails'],
  },
  {
    sideText: 'Room Photos',
    Component: () => <RoomPhotos />,
    completed: false,
    fields: ['property.roomPhotos']
  },
  {
    sideText: 'Extra Details',
    Component: () => <ExtraDetails />,
    completed: false,
    fields: ['property.extraDetails'], // property.bus_by, property.bus_mins, property.uni_by, property.uni_mins, property.cc_by, property.cc_mins,  
    options: [
      'Shared Bathroom',
      'Private Bathroom',
      'Kitchen',
      'Living Room',
      'Dining Area',
      'Washing Machine',
      'Microwave',
      'Internet (Wi-Fi)',
      'Fridge',
      'Freezer',
      'Oven',
      'Induction Cooktop',
      'Dishwasher',
      'Vacuum Cleaner',
      'Heating System',
      'Cellar',
      'Balcony',
      'Parking'
    ]
    
    
  },
  {
    sideText: 'Profile Details',
    Component: () => <ProfileDetails/>,
    completed: false,
    fields: [ 'user.profilepic', 'user.name', 'user.age', 'user.language', 'user.location', 'user.gender']
  },
  {
    sideText: 'Complete Profile',
    Component: () => <CompleteProfile />,
    completed: false,
    fields: ['user.email', 'user.password', 'user.confirmPassword']
  }

] as Array<PageType>;


export const SeekerPages = [
  {
    sideText: 'Do you smoke?',
    Component: () => <EnumPage />,
    completed: false,
    fields: ['tenantData.smoke'],
    enum: true,
    enum_val: Smoke
  },
  {
    sideText: 'How clean do you prefer your home to be?',
    Component: () => <EnumPage />,
    completed: false,
    fields: ['tenantData.cleanliness'],
    enum: true,
    enum_val: Cleanliness
  },
  {
    sideText: 'What about pets?',
    Component: () => <EnumPage />,
    completed: false,
    fields: ['tenantData.pets'],
    enum: true,
    enum_val: Pets
  },
  {
    sideText: 'How often would you like to socialize with your roommates?',
    Component: () => <EnumPage />,
    completed: false,
    fields: ['tenantData.socialize'],
    enum: true,
    enum_val: Socialize
  },
  {
    sideText: 'How often do you cook?',
    Component: () => <EnumPage />,
    completed: false,
    fields: ['tenantData.cook'],
    enum: true,
    enum_val: Cook
  },
  {
    sideText: 'What describes you best?',
    Component: () => <Description />,
    completed: false,
    fields: ['tenantData.descriptions'],
    enum: true,
    options: [
      "Adventurous", "Extrovert", "Introvert", "Music Lover", "Movie Lover",
      "Foodie", "Tech Enthusiast", "Traveler", "Cyclist", "Gamer",
      "Fitness Fanatic", "Nature Lover", "Photographer", "Yoga Practitioner", "Pet Owner",
      "DIY Crafter", "Art Lover", "Social Butterfly", "Avid Reader", "Sports Fan" , "Netflix", "Funny"
    ]
    ,
    nextBtn: true
  },
  {
    sideText: 'Room Preferences',
    Component: () => <RoomPreferences />,
    completed: false,
    fields: ['tenantData.budget', 'tenantData.city', 'tenantData.moveIn', 'tenantData.moveInDate', 'tenantData.moveOut', 'tenantData.moveOutDate']
  },
  {
    sideText: 'About You',
    Component: () => <AboutYou />,
    completed: false,
    fields: ['user.profilepic', 'user.name', 'user.age', 'user.language', 'user.location', 'user.gender', 'tenantData.occupation']
  },
  {
    sideText: 'Get notified',
    Component: () => <GetNotified />,
    completed: false,
    fields: ['tenantData.phoneNumber']
  },
  {
    sideText: 'Photo Highlights',
    Component: () => <PhotoHighlights />,
    completed: false,
    fields: ['tenantData.photoHighlights']
  },
  {
    sideText: 'Create your account',
    Component: () => <CompleteProfile />,
    completed: false,
    fields: ['user.email', 'user.password', 'user.confirmpwd']
  },
] as Array<PageType>;


export interface AllPagesContextInterface {
  allPages: Array<PageType>;
  setAllPages: Dispatch<SetStateAction<Array<PageType>>>;
}

const defaultState = {
  allPages: [],
  setAllPages: (allPages: Array<PageType>) => {},
} as AllPagesContextInterface;

export const AllPagesContext = createContext(defaultState);

import { StatusEnum } from "utils";
import { SubHeader } from "../SubHeader";
import { useGetTenants } from "lib";
import { useContext, useEffect, useRef, useState } from "react";
import { Flex } from "@chakra-ui/react";
import { Spinner } from "components/atoms";
import { TenantPost } from "./TenantPost";
import { ReviewButtons } from "../ReviewButtons";
import { useSwipeTenant } from "lib/mutations/useSwipeTenant";
import { DashboardListing } from "context";

export const TenantPosts = () => {
  // let [ page, setPage ] = useState<string>(''); // contains the id of the current tenant being shown
  let [ tenants, setTenants ] = useState<any[]>([]);

  const { listingId: page, setListingId: setPage } = useContext(DashboardListing);

  let { isFetching, isError, data: tenantData } = useGetTenants(page);
  const {
    shortlistMutation,
    acceptMutation,
    rejectMutation
  } = useSwipeTenant();
  
  useEffect(() => {
    if (tenantData){
      setTenants([tenantData]);
    }
    else if (tenantData === null){
        alert('All Done');
        setTenants([]);
        setPage('');
    }
  }, [tenantData]);

  const moveItem = (animation: "animate__zoomOutUp"|"animate__fadeOutRight"|"animate__fadeOutLeft") => {
    if (postRef.current){
      setTimeout(() => next(),200);
      postRef.current.classList.remove('animate__animated', 'animate__zoomIn');
      postRef.current.classList.add('animate__animated', animation);  
    }
  }

  const shortlistTenant = (tenantId: string) => {
    shortlistMutation.mutate(tenantId, {
      onSuccess: () => 
        moveItem("animate__zoomOutUp")
    });
  }
  const acceptTenant = (tenantId: string) => {
    acceptMutation.mutate(tenantId, {
      onSuccess: () =>  
        moveItem("animate__fadeOutRight")
    });
  }
  const rejectTenant = (tenantId: string) => {
    // rejectMutation.mutate(tenantId, {
    //   onSuccess: () => 
        moveItem("animate__fadeOutLeft")
    // });
  }

  const nextItem = (dir: "accept"|"reject"|"shortlist") => {
    if (tenantData){
      if (dir === "shortlist"){
        shortlistTenant(tenantData._id)
      }
      if (dir === "accept"){
        acceptTenant(tenantData._id)
      }
      if (dir === "reject"){
        rejectTenant(tenantData._id)
      }
    }
  }

  const next = () => {
    if (tenantData){
      setPage(tenantData._id);  
    }
  }

  const postRef = useRef<HTMLDivElement|null>(null);
  
  useEffect(() => postRef?.current?.focus(), []);

  return(
    <>
      <SubHeader status={StatusEnum.Renter} />
      {isFetching ? 
      <Flex alignItems={"center"} justifyContent={'center'} height={'100%'} >
        <Spinner />
      </Flex>
      : <></>}
      {!isFetching && !isError ? (
        <>
          {tenants.map((tenant, i) => (
            <TenantPost key={tenant._id} ref={postRef} tenant={tenant} />
          ))}
          <ReviewButtons 
          onClick={(a) => {nextItem(a)}}
          />
        </>
      ) : (<></>)}

    </>
  );
}
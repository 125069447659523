import { Image } from "@chakra-ui/react"
import { useRef } from "react";
import { useField } from "formik";
import { ErrorText } from 'components/molecules';
import defaultPfp from 'assets/images/defaultPfp.png';

type ImageInputFieldProps = {
  value: File|null,
  name:string,
  width?: string,
  height?: string,
  fileChange: (e:any) => void
};

export const ImageInputField = ({
  value,
  name,
  width='125px',
  height='125px',
  fileChange
}: ImageInputFieldProps) => {
  let imgInput = useRef<HTMLInputElement|null>(null);
  const [field, { touched, error }] = useField(name);

  return(
    <>
    <Image 
    src={value ? URL.createObjectURL(value) : defaultPfp} 
    borderRadius={value ? 'full' : 'initial'}
    cursor={'pointer'}
    width={width}
    height={height}
    objectFit={'cover'}
    onClick={() => imgInput.current ? imgInput.current.click() : ''}
    />
    {(error && touched) ? (<ErrorText>{error}</ErrorText>) : (<></>)}
    <input ref={imgInput} onChange={fileChange} style={{display: 'none'}} type='file' accept='image/png,image/jpeg,image/jpg' />
    </>

  );
}
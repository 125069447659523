import { RadioField } from "components/molecules";
import { enumToObject, GenderEnum } from "utils";
import { FormikValues, useFormikContext } from "formik";
import { useEffect } from "react";

export const EditGender = () => {
  const { values, setFieldValue }: FormikValues = useFormikContext();
  
  useEffect(() => {
    if (values.gender){
      setFieldValue('gender', GenderEnum[values.gender].toString())
    }
  }, [])
  
  return(
    <RadioField
      isRequired={true}
      label="Gender"
      options={enumToObject(GenderEnum)}
      name="gender"
      value={values.gender}
    />

  );
}
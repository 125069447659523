import {
  //
  Checkbox as CCheckbox,
  CheckboxGroup as CCheckboxGroup,
  Input as CInput,
  FormLabel as CFormLabel,
  Radio as CRadio,
  RadioGroup as CRadioGroup,
  Select as CSelect,
  theme,
} from '@chakra-ui/react';
import { primaryColorScheme } from '../../../../utils';

export const Checkbox = CCheckbox;
CCheckbox.defaultProps = {
  colorScheme: primaryColorScheme,
};

export const CheckboxGroup = CCheckboxGroup;

export const Input = CInput;
Input.defaultProps = {
  errorBorderColor: theme.colors.red[400],
  
};

export const FormLabel = CFormLabel;

export const Radio = CRadio;
CRadio.defaultProps = {
  colorScheme: primaryColorScheme,
};

export const RadioGroup = CRadioGroup;

export const Select = CSelect;

import { useState, useEffect } from "react";
import { Spacer, Text } from "@chakra-ui/react";
import { FormikValues, useFormikContext } from "formik";
import { Select } from "chakra-react-select";

import {
  InputDateField,
  Heading1,
  SliderComp,
  RadioField,
  SearchSelectField,
} from "components/molecules";

export const RoomPreferences = (): JSX.Element => {
  const { values, setFieldValue }: FormikValues = useFormikContext();

  const [cities, setCities] = useState<{ value: string; label: string }[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");

  // Fetch cities from Photon API
  const fetchCities = async (query: string) => {
    if (query.length < 3) {
      setCities([]);
      return;
    }
    const bbox = "5.866,47.270,15.042,55.058"; // Germany's approximate geographic bounding box: [minLon, minLat, maxLon, maxLat]

    const url = `https://photon.komoot.io/api/?q=${query}&lang=en&limit=10&bbox=${bbox}`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      const cityList = data.features
        .filter((item: any) =>
          item.properties.type === "city" && item.properties.osm_value !== "village"    && item.properties.osm_value !== "town"
        )
        .map((item: any) => ({
          value: item.properties.name,
          label: item.properties.name,
        }));
      setCities(cityList);
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  // Debounce fetch function to avoid multiple API calls
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (searchQuery.length >= 3) {
        fetchCities(searchQuery);
      }
    }, 500); // Adjust debounce time as needed
    return () => clearTimeout(timeoutId); // Cleanup on unmount or query change
  }, [searchQuery]);

  return (
    <>
      <Heading1>Room Preferences</Heading1>
      <Spacer my={4} flex={0} />
      <SliderComp
        step={50}
        name="tenantData.budget"
        label="Max budget for the room?"
        postfix="€"
        min={0}
        max={5000}
        sliderValue={values.tenantData.budget}
      />
      <Spacer my={5} flex={0} />
      {/* City Dropdown */}
      <Spacer my={1} flex={0} />

      <SearchSelectField 
        isRequired={true}
        label="Which city should the room be located in?"
        name="tenantData.city"
        options={cities}
        placeholder="Startholding a city..."
        value={
          cities.find((city) => city.value === values.tenantData.city) || null
        }
        noOptionsMessage={() =>
          searchQuery.length < 3
            ? "Type at least 3 characters to search"
            : "No cities found"
        }
        onInputChange={(inputValue) => setSearchQuery(inputValue)} // Update query
        onChange={(selectedOption) =>
          setFieldValue("tenantData.city", selectedOption?.value)
        }
      />
      <Spacer my={5} flex={0} />
      <RadioField
        isRequired={true}
        label="Preferred move-in date?"
        options={{ now: "As soon as possible", later: "Select Date" }}
        value={values.tenantData.moveIn}
        name="tenantData.moveIn"
      />
      {values.tenantData.moveIn === "later" && (
        <InputDateField
          isRequired={true}
          isDisabled={values.tenantData.moveIn === "now"}
          label={"Date"}
          minDate={new Date()}
          value={values.tenantData.moveInDate}
          name="tenantData.moveInDate"
        />
      )}
      <Spacer my={5} flex={0} />
      <RadioField
        isRequired={true}
        label="For how long is the apartment available?"
        options={{ now: "As long as possible", later: "Select date" }}
        value={values.tenantData.moveOut}
        name="tenantData.moveOut"
      />
      {values.tenantData.moveOut === "later" && (
        <InputDateField
          isRequired={true}
          minDate={
            values.tenantData.moveInDate
              ? new Date(values.tenantData.moveInDate)
              : new Date()
          }
          isDisabled={values.tenantData.moveOut === "now"}
          label={"Date"}
          name="tenantData.moveOutDate"
          value={values.tenantData.moveOutDate}
        />
      )}
    </>
  );
};

import { Spacer } from '@chakra-ui/react';
import { InputField, Heading1 } from 'components/molecules';
import { FormikValues, useFormikContext } from 'formik';


export const GetNotified = (): JSX.Element => {
  let { values }: FormikValues = useFormikContext();
  return(
    <>
    <Heading1>Get notified instantly</Heading1>
    <Spacer my={4} flex={0} />
    <InputField
      name='tenantData.phoneNumber'
      value={values.tenantData.phoneNumber}
      isRequired={true}
      label='Phone number'
      placeholder='+49 151 1234 1234'
      // locationInput={true}
    />
    </>
  )
}
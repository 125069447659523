import React from 'react';
import { CheckboxGroupProps } from '@chakra-ui/react';
import { StringOrNumber } from '@chakra-ui/utils';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/form-control';
import { VStack } from '@chakra-ui/layout';
import { useField } from 'formik';
import { Checkbox, CheckboxGroup } from 'components/atoms';
// import { withItem } from './../../../../utils';

interface CheckboxProps extends CheckboxGroupProps {
  isRequired: boolean;
  label: string;
  name: string;
  options: { [key: string]: string };
  direction?: 'horizontal' | 'vertical';
  value?: StringOrNumber[];
  onBlur: CheckboxGroupProps['onChange'];
}

export const CheckboxField: React.FC<Omit<CheckboxProps, 'children'>> = ({
  label,
  isRequired = false,
  options = {},
  ...props
}) => {
  const [field, meta] = useField({
    type: 'checkbox',
    name: props.name,
    value: String(props.value),
  });
  const { onChange, onBlur } = field;
  return (
    <FormControl width={'fit-content'} isInvalid={!!meta.error} isRequired={isRequired}>
      {label ? <FormLabel>{label}</FormLabel> : <></>}
      <CheckboxGroup>
        <VStack align="left">
          {/* {withItem((value, key) => {
            return (
              <Checkbox
                key={key}
                onBlur={onBlur}
                onChange={onChange}
                name={field.name}
                value={key}
              >
                {value}
              </Checkbox>
            );
          })(options)} */}
          {Object.keys(options).map((val, key) => (
              <Checkbox
              key={key}
              onBlur={onBlur}
              onChange={onChange}
              name={field.name}
              value={key}
            >
              {options[val]}
            </Checkbox>
          ))}
        </VStack>
      </CheckboxGroup>
      {meta.touched && meta.error ? <FormErrorMessage>{meta.error}</FormErrorMessage> : null}
    </FormControl>
  );
};

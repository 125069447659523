import { ChakraProvider } from '@chakra-ui/provider';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import { Notifications } from "components/organisms";
import { theme } from "styles/theme";
import { AppProviderProps } from "types";

const queryClient = new QueryClient()

export const AppProvider = ({ children }: AppProviderProps) => {
  return(
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        {children}
        <Notifications />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ChakraProvider>
  );
}
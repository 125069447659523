import * as React from 'react';
import { Flex } from '@chakra-ui/react';

export type LayoutVariant = 'small' | 'regular' | 'large';

interface LayoutProps {
  children: React.ReactNode;
  variant?: LayoutVariant;
}

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <Flex w="100%" direction="column">
      <Flex w="100vw" as="main" direction="column" minHeight='100vh' flexWrap='wrap'
      
      >
        {children}
      </Flex>
    </Flex>
  );
};
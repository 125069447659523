import { Box, Divider, Flex, Grid, GridItem, Image, Spacer, theme, VStack } from "@chakra-ui/react";
import { Body0, Body1, Caption2, Swiper, Title1, Title3, OptionGroup, Title4 } from "components/molecules";
import { SquareArrowOutUpRight } from "lucide-react";
import React, { ForwardedRef, forwardRef } from "react";
import RoomSize from 'assets/images/RoomSizeIcon.svg';
import Location from 'assets/images/LocationIcon.svg';
import Roommate from 'assets/images/RoommateIcon.svg';
import Calendar from 'assets/images/CalendarIcon.svg';
import Language from 'assets/images/LanguageIcon.svg';
import SearchingFor from 'assets/images/SearchingForIcon.svg';
import Education from 'assets/images/EducationIcon.svg';
import Profession from 'assets/images/ProfessionIcon.svg';
import Gender from 'assets/images/GenderIcon.svg';
import GlobeIcon from 'assets/images/GlobeIcon.svg';
import SmokeIcon from 'assets/images/SmokeIcon.svg';
import CleanlinessIcon from 'assets/images/CleanlinessIcon.svg';
import Deposit from 'assets/images/DepositIcon.svg';
import { TenantType } from "types";
import HomeIcon from 'assets/images/HomeIcon.svg';
import { dateFormatter, GenderEnum, getDurationOfStay, Smoke, Occupation, Cleanliness, Pets, Socialize, Cook } from "utils";


let roomP = [
  'uploads/roomPhotos-0c38b00641901dc7.jpeg',
  'uploads/roomPhotos-2c2b88eff1978586.jpeg',
  'uploads/roomPhotos-0c38b00641901dc7.jpeg',
  'uploads/roomPhotos-0c38b00641901dc7.jpeg',
  'uploads/roomPhotos-f3834db7b6933881.jpeg',
  'uploads/profilepic-10a957605206f07c.png'
]

let roommateP = [
  'uploads/roommateDetails-pfp-3f209f41a567069b.png',
  'uploads/roommateDetails-pfp-b1a97bfba46f617f.jpeg'
]

type RoomDetailProps = {
  icon: any, 
  name: string, 
  value : any
}

const RoomDetail = ({icon, name, value}: RoomDetailProps) => (
  <GridItem colSpan={1}>
    <Flex flexDir={'row'} alignItems={'center'}>
      <Box cursor={'pointer'} marginRight={2} borderRadius={'50%'} backgroundColor={'rgba(0,0,0,0.05)'} padding={'2'}>
        <Image src={icon} />
      </Box>
      <Flex flexDir={'column'}>
        <Body1>
          {name}
        </Body1>
        <span style={{color: theme.colors.black[200]}}>{value}</span>
      </Flex>
    </Flex>
  </GridItem>
)

const interests = ["Non-smoker", "Likes to clean", "Coffee", "Touring", "Music", "Reading"];

export const TenantPost = forwardRef((
  {
    tenant,
    styles={}
  }:
  {
    tenant: TenantType
    styles?: React.CSSProperties
  },
  ref: ForwardedRef<HTMLDivElement|null>) => {
  
  let { name, tenantData, age, location: currLocation, language, gender } = tenant;
  let { 
    photoHighlights: pictures, 
    moveInDate, moveOutDate, 
    occupation, city: preferredLocation,
    smoke, cleanliness, pets, socialize,
    cook, descriptions
  } = tenantData;
  return (
    <Flex 
    ref={ref}
    flexDir={['column', 'row']} 
    alignItems={'center'} 
    justifyContent={'center'} 
    paddingTop={[5, 10]}
    overflow={'hidden'}
    style={styles}
    className="animate__animated animate__zoomIn"
    >

      {/* Tenant details in this flex */}
      <Flex 
      width={["90vw", "75vw"]} 
      flexDir={['column', 'row']} 
      border={'1px solid rgba(216, 220, 224, 1)'} 
      borderRadius={'16px'} padding={'16px'} maxH={'100%'}
      className="apartment-details" overflow={['scroll', 'hidden']}>
        {/* Room photos carousel  */}
        <Swiper border="none" width="100%" height="75vh" photoUrls={pictures.map((r: string) => `${process.env.REACT_APP_BASE_URL}/${r}`)} />

        <VStack padding={6} width={'100%'} maxH={'100%'} className="apartment-details" overflow={['hidden', 'scroll']}>
          <Flex justifyContent={'space-between'} width={'100%'} alignItems={'center'} >
            <Flex flexDir={'row'} alignItems={'baseline'}>
            <Title3>{name}</Title3>
            <Spacer mr={1} />
            <Title4 color='black.800'>{age}</Title4>
            </Flex>
            <Flex flexDir={'column'} alignItems={'center'}>
              <Box cursor={'pointer'} borderRadius={'50%'} backgroundColor={'rgba(0,0,0,0.05)'} padding={'2'}>
                <SquareArrowOutUpRight size={'16px'} />
              </Box>
              <Caption2 color={theme.colors.black[800]}>
                Share
              </Caption2>
            </Flex>
          </Flex>
          <Grid width={'100%'} templateRows='repeat(1, 1fr)' templateColumns={[`repeat(1, 1fr)`, `repeat(2, 1fr)`]} gap={2}>
            <RoomDetail icon={HomeIcon} name="Move-in date" value={<>{dateFormatter(moveInDate)}</>} />
            <RoomDetail icon={Location} name="Preferred location" value={preferredLocation} />
            <RoomDetail icon={HomeIcon} name="Duration of Stay" value={getDurationOfStay(moveInDate, moveOutDate)} />
            <RoomDetail icon={Language} name="Languages" value={language} />

            <RoomDetail icon={Location} name="Current Location" value={currLocation} />
            <RoomDetail icon={Education} name="Education" value={Occupation[occupation]} />
            <RoomDetail icon={Profession} name="Profession" value={Occupation[occupation]} />
            <RoomDetail icon={GlobeIcon} name="From" value={currLocation} />
            <RoomDetail icon={Gender} name="Gender" value={GenderEnum[gender]} />
          </Grid>
          <Divider orientation="horizontal" my={2}/>

          <Grid width={'100%'} templateRows='repeat(1, 1fr)' templateColumns={[`repeat(1, 1fr)`, `repeat(2, 1fr)`]} gap={2}>
            <GridItem colSpan={[1, 2]}>
            <Title1>Additional Information</Title1>
            </GridItem>
            <RoomDetail icon={SmokeIcon} name="Smoke" value={Smoke[smoke]} />
            <RoomDetail icon={CleanlinessIcon} name="Cleanliness" value={Cleanliness[cleanliness]} />
            <RoomDetail icon={SmokeIcon} name="Pets" value={Pets[pets]} />
            <RoomDetail icon={CleanlinessIcon} name="Socializing" value={Socialize[socialize]} />
            <RoomDetail icon={SmokeIcon} name="Cooking" value={Cook[cook]} />
          </Grid>
          <Divider orientation="horizontal" my={2}/>

          <Grid width={'100%'} templateRows='repeat(1, 1fr)' templateColumns={[`repeat(1, 1fr)`, `repeat(2, 1fr)`]} gap={2}>
            <GridItem colSpan={[1, 2]}>
            <Title1>Interests</Title1>
            </GridItem>
          </Grid>
          <OptionGroup nonFunctional={true} selected={interests} options={interests} onChange={(v) => {}} columns={4} />
          <Divider orientation="horizontal" my={2}/>
          <Flex flexDir={'column'}>
            <Title1>Bio</Title1>
            <Body0>
            Social media influencer dedicated to inspiring and empowering others through creativity and authenticity. Join me on this journey of self-expression and connection. 🌟
            </Body0>
          </Flex>
        </VStack>

      </Flex>

    </Flex>
  );
})
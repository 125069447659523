import { Grid, GridItem, Spacer, FormLabel } from '@chakra-ui/react';
import { FormikValues, useFormikContext } from 'formik';
import { Select } from 'chakra-react-select'; // Import Select from chakra-react-select

import { ImageInputField, SelectField, Heading1, RadioField, InputField } from 'components/molecules';
import { enumToObject, GenderEnum, Occupation } from 'utils';
import { join } from 'path';
import { countries, languages } from 'utils/data';

export const AboutYou = (): JSX.Element => {
  const { values, setFieldValue }: FormikValues = useFormikContext();

  const fileChange = (e: any) => {
    if (e.target.files.length > 0) {
      setFieldValue('user.profilepic', e.target.files[0]);
    }
  };



  return (
    <>
      <Heading1>Tell Us About Yourself</Heading1>
      <Spacer flex={0} my={3} />
      <Grid
        templateRows="repeat(1, 1fr)"
        templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
        gap={4}
      >
        <GridItem colSpan={[1, 2]}>
          <ImageInputField
            value={values.user?.profilepic}
            fileChange={fileChange}
            name="user.profilepic"
          />
        </GridItem>
        <GridItem colSpan={1}>
          <InputField
            name="user.name"
            value={values.user.name}
            isRequired={true}
            label="Full Name"
            placeholder="Jonas"
          />
        </GridItem>
        <GridItem colSpan={1}>
          <InputField
            name="user.age"
            value={values.user.age}
            isRequired={true}
            label="Age"
            type="number"
            placeholder="25"
          />
        </GridItem>
        <GridItem colSpan={1}>
        <FormLabel  htmlFor="user.language" textStyle="medium">Which languages do you speak?</FormLabel>

          <Select
            name="user.language"
            options={languages}
            placeholder="Select languages"
            isMulti
            closeMenuOnSelect={false}
            selectedOptionStyle="check" // Optional: Highlight selected items
            value={languages.filter((lang) =>
              values.user.language?.includes(lang.value)
            )}
            onChange={(selected) => {
              const selectedValues = selected.map((option) => option.value);
              console.log(selectedValues.join(','))
              setFieldValue("user.language", selectedValues.join(','));
            }}
          />
        </GridItem>
        <GridItem colSpan={1}>
          <FormLabel textStyle="medium" htmlFor="user.location">
            Where are you from?
          </FormLabel>
          <Select
            name="user.location"
            options={countries}
            placeholder="Select a country"
            isSearchable
            value={countries.find((c) => c.value === values.user.location)}
            onChange={(selected) => {
              console.log(selected)
              setFieldValue("user.location", selected?.value)
            }}
          />
        </GridItem>
        <GridItem colSpan={1}>
          <SelectField
            name="tenantData.occupation"
            label="Occupational Status"
            isRequired={true}
            options={enumToObject(Occupation)}
            chakraProps={{
              placeholder: values.tenantData.occupation
                ? Occupation[values.tenantData.occupation]
                : "-",
            }}
          />
        </GridItem>
        <GridItem colSpan={1}>
          <RadioField
            isRequired={true}
            label="Gender"
            options={enumToObject(GenderEnum)}
            name="user.gender"
            value={values.user.gender}
          />
        </GridItem>
      </Grid>
    </>
  );
};

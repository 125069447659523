import { theme } from '@chakra-ui/react';

export const primaryColorScheme = 'green';
export const primaryColor = theme.colors.green[500];
export const primaryColorLight = theme.colors.green[100];
export const primaryColorExtraLight = theme.colors.green[50];

export const blackHeavy = theme.colors.black[900];
// const bbb = "#a9a9a9";
export const blackLight = theme.colors.black[800];

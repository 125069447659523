import React from 'react';
import {
  RadioGroupProps as ChakraRadioGroupProps,
  StackProps,
} from '@chakra-ui/react';
import { FormControl, FormErrorMessage } from '@chakra-ui/form-control';
import { Flex, HStack } from '@chakra-ui/layout';
import { useField } from 'formik';
import { Radio, RadioGroup, FormLabel } from './../../../atoms/Form';

export type RadioGroupProps = ChakraRadioGroupProps & {
  stackProps?: StackProps;
  isRequired: boolean;
  label: string;
  options: { [key: string]: string };
  direction?: 'horizontal' | 'vertical';
};

export const RadioField: React.FC<Omit<RadioGroupProps, 'children'>> = ({
  label,
  isRequired = false,
  options = {},
  ...props
}) => {
  const [
    //
    field,
    meta
  ] = useField({
    type: 'radio',
    name: props.name ? props.name : '',
    value: props.value,
  });
  const {
    //
    onChange,
    onBlur,
    // ...rest
  } = field;
  
  return (
    <FormControl isInvalid={!!meta.error && !!meta.touched} isRequired={isRequired}>
      <FormLabel textStyle="medium" mb={4}>{label}</FormLabel>
      <RadioGroup {...props}>
        <Flex gap={5} flexDir={['column', 'row']}>
          {Object.keys(options).map((key, i) => (
              <Radio
              key={key}
              onBlur={onBlur}
              onChange={onChange}
              name={field.name}
              value={key}
              checked={props.value === key}
              >
              {options[key]}
            </Radio>
          ))}
        </Flex>
      </RadioGroup>
      {(meta.error && meta.touched) ? <FormErrorMessage>{meta.error}</FormErrorMessage> : null}
    </FormControl>
  );
};

import { StatusEnum } from "utils";
import { RenterAcceptedApplications } from "./Renter";
import { TenantAcceptedApplications } from "./Tenant";

export const AcceptedApplications = ({status}: {status: number}) => {
  return(
    <>
      {status === StatusEnum.Tenant ? (
        <TenantAcceptedApplications />
      ) : (<></>)}
      {status === StatusEnum.Renter ? (
        <RenterAcceptedApplications />
      ) : (<></>)}
    </>
  );
}
import { Dispatch, SetStateAction } from "react";
import { createContext } from "react";

export interface DashboardListingContextInterface {
  listingId: string;
  setListingId: Dispatch<SetStateAction<string>>;
}

const defaultState = {
  listingId: '',
  setListingId: (listingId: string) => {}
} as DashboardListingContextInterface;

export const DashboardListing = createContext(defaultState);

import { Avatar, Flex, VStack } from "@chakra-ui/react";
import { Body1, Body4 } from "components/molecules";
import React from "react";

// component for row in applications page for all three tabs for both tenant and renters
export const ApplicationRow = ({
  img,
  main,
  subMain,
  buttons
} : {
  img: any,
  main: string,
  subMain: React.ReactNode,
  buttons: React.ReactNode
}) => (
  <Flex flexDir={['column', 'row']} justifyContent={['flex-start', 'space-between']}>
    <Flex>
      <Flex>
        <Avatar marginRight={'10px'} height={'50px'} width={'50px'} src={img} />
        <VStack alignItems={'flex-start'} gap={0}>
          <Body1>{main}</Body1>
          <Body4>
            {subMain}
          </Body4> 
        </VStack>
      </Flex>
    </Flex>
    <Flex flexDir={['column', 'row']}>
      {buttons}
    </Flex>
  </Flex>
)
import { Flex, Img } from "@chakra-ui/react";
import logo from "assets/images/Logo.svg";
import { Heading1 } from "../molecules";

type LogoWithNameProps = {
  color?: string;
  noName?: Boolean;
  fontSize?: string;
  size?: string; // Add a size prop to control the logo size
};

export const LogoWithName = ({
  color,
  noName,
  fontSize = "16px",
  size = "20px",
}: LogoWithNameProps) => {
  return (
    <Flex
      justifyContent={"center"}
      alignItems={"center"}
      height={"fit-content"}
    >
      {/* Use the size prop to set the logo size */}
      <Img src={logo} boxSize={size} />
      {noName ? (
        <></>
      ) : (
        <Heading1 fontSize={fontSize} ml="2" color={color}>
          HeyWG
        </Heading1>
      )}
    </Flex>
  );
};

import React, { FC, InputHTMLAttributes, useState } from 'react';
import { useField } from 'formik';
import { FormControl, FormErrorMessage, InputProps, InputGroup, InputRightElement, Button, Image } from '@chakra-ui/react';
import { Input, FormLabel, Text4 } from 'components/atoms';
import locationIcon from 'assets/images/location.svg';


type InputFieldProps = InputHTMLAttributes<HTMLInputElement> & HideAndShowPasswordInputProps & {
  isRequired: boolean;
  controlStyles?: {[s: string]: string|number|Array<string>};
};

interface HideAndShowPasswordInputProps {
  name: string;
  label?: string;
};

const HideAndShowPasswordInput: FC<HideAndShowPasswordInputProps & InputProps> = ({
  ...props
}) => {
  const [show, setShow] = useState<boolean>(false);

  return(
    <InputGroup {...props}>
      <Input
        {...props}
        type={show ? 'text' : 'password'}
        value={props.value}
        id={props.name}
      />
      <InputRightElement width='max-content' justifyContent='right' pr={1} >
      <Button size='sm' onClick={() => setShow(!show)}>
        {show ? 'Hide' : 'Show'}
      </Button>
    </InputRightElement>
    </InputGroup>
  );
}

const LocationInput: FC<InputProps> = ({
  ...props
}) => {
  return(
    <InputGroup>
      <Input
        {...props}
        type={'text'}
        value={props.value}
        id={props.name}
      />
      <InputRightElement width='max-content' justifyContent='right' pr={1} >
        <Image src={locationIcon} />
      </InputRightElement>
    </InputGroup>
  );
}

export const InputField: FC<InputFieldProps & InputProps & { disabledView?: boolean, locationInput?: boolean }> = ({
  label,
  type = 'text',
  isRequired = false,
  disabledView = false,
  locationInput = false,
  controlStyles = {},
  
  
  ...props
}) => {
  const [field, { touched, error }] = useField({...props, type});

  return (

    <FormControl {...controlStyles} isInvalid={!!error && !!touched} isRequired={isRequired}>
      {label && <FormLabel textStyle="medium" htmlFor={field.name}>{label}</FormLabel>}
      {
        !disabledView ? (
          <>
          {type === "password" ? (
            <HideAndShowPasswordInput 
              {...field}
              {...props}
              />
          ) : (
            <>
            {locationInput ? 
            <LocationInput 
              {...field}
              {...props}
              />
            : (<Input
              type={type}
              id={field.name}
              isRequired={isRequired}
              {...field}
              {...props}
              // value={field.value}
            />)}
            </>
          )}
          </>
          
        ) : (
          <></>
        )
      }
      { disabledView ? <Text4 textAlign='left'>{props.value}</Text4>: <></> }
      {(touched && error) ? <FormErrorMessage>{error}</FormErrorMessage> : null}
    </FormControl>
  );
};

import { styled } from 'styles';
import { Button as AButton } from '@chakra-ui/react';

export const NextButton = styled(AButton)``;
NextButton.defaultProps = {
  padding: '1.3rem 3rem',
  colorScheme: 'green',
  marginLeft: 'auto',
  marginTop: '20px'
};

import { Select } from "chakra-react-select";
import { FormLabel } from "components/atoms";
import { FormikValues, useFormikContext } from "formik";
import { values } from "ramda";
import { countries, languages } from "utils/data";

export const EditCountry = () => {
  const { values, setFieldValue }: FormikValues = useFormikContext();

  return(
    <>
      <Select
        name="location"
        options={countries}
        placeholder="Select a country"
        isSearchable
        value={countries.find((c) => c.value === values.location)}
        onChange={(selected) => {
          setFieldValue("location", selected?.value)
        }}
      />
    </>
  );
}
import { 
  Slider, 
  SliderFilledTrack, 
  SliderTrack, 
  SliderThumb, 
  Tooltip, 
  SliderMark, 
  FormControl 
} from "@chakra-ui/react";
import React, { FC } from "react";
import { useField, useFormikContext } from "formik";
import { FormLabel } from './../../../atoms/Form';
import { ErrorText } from "../../Text";

type SliderCompProps = {
  label: string; 
  min: number; 
  max: number; 
  name: string;
  sliderValue: number;
  postfix?: string;
  step?: number;
};

export const SliderComp: FC<SliderCompProps> = ({ 
  label, 
  min, 
  max, 
  postfix, 
  sliderValue, 
  name, 
  step = 1 
}) => {
  const [showTooltip, setShowTooltip] = React.useState(false);
  let { setFieldValue } = useFormikContext();
  const [field, { touched, error }] = useField(name);
  const setSliderValue = (val: number) => {
    setFieldValue(name, val);
  };

  return (
    <FormControl>
      <FormLabel>{label}: {min} - {sliderValue} {postfix}</FormLabel>
      <Slider
        defaultValue={sliderValue}
        min={min}
        max={max}
        step={step}
        colorScheme="green"
        focusThumbOnChange={false}
        onChange={(v) => setSliderValue(v)}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        {/* Add slider marks */}
        
        <SliderMark 
          value={sliderValue} 
          textAlign="center" 
          mt="-10" 
          ml="-5" 
          fontSize="sm" 
          color="green.500"
        >
          {sliderValue} {postfix}
        </SliderMark>

        <SliderTrack bg="green.100">
          <SliderFilledTrack bg="green.500" />
        </SliderTrack>
        <Tooltip
          hasArrow
          bg="green.500"
          color="white"
          placement="top"
          isOpen={showTooltip}
          label={`${sliderValue} ${postfix}`}
        >
          <SliderThumb bg="green.500" />
        </Tooltip>
      </Slider>
      {error && touched ? <ErrorText>{error}</ErrorText> : null}
    </FormControl>
  );
};

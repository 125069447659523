import { TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { ApplicationTabs } from "./ApplicationTabs";
import { useUser } from "lib";
import { StatusEnum } from "utils";
import { ShortlistedApplications } from "./ShortlistedApplications";
import { PendingApplications } from "./PedingApplications";
import { AcceptedApplications } from "./Accepted";
import { ReactNode } from "react";

let status: {[k: number]: string} = {
  [StatusEnum.Renter]: 'Tenants',
  [StatusEnum.Tenant]: 'Rooms'
}

const TabPanelLayout = ({children}: {children: ReactNode}) => (
  <TabPanel 
    flexDir={'column'} 
    overflow={'auto'}
    height={'100%'}
    minH={'100%'} maxHeight={'100%'}
>
    {children}
  </TabPanel>
)

export const ApplicationsView = () => {
  let {data: user} = useUser();
  
  if (user){
    return(
      <Tabs variant={'unstyled'}  maxHeight={'100%'} height={'100%'} >
        <ApplicationTabs data={status[user.status]} />
        <TabPanels
        height={'calc(100% - 56px)'} 
          overflow={'scroll'}
          className="applications"        
            

        border={'1px solid rgba(216, 220, 224, 1)'} 
        borderRadius={'16px'} 
        padding={4} mt={3}>
          <TabPanelLayout>
            <AcceptedApplications status={user.status} />
          </TabPanelLayout>
          <TabPanelLayout>
            <PendingApplications status={user.status} />
          </TabPanelLayout>
          <TabPanelLayout>
            <ShortlistedApplications status={user.status} />
          </TabPanelLayout>
        </TabPanels>
      </Tabs>
    );  
  }
  return(<></>)
}
import { Text, TextProps } from '@chakra-ui/react';
import { styled } from './../../../styles';
const BaseText = styled(Text)<TextProps & { forwardedAs?: string }>``;

export const Text1 = styled(BaseText)``;
Text1.defaultProps = {
  fontSize: 'xxs',
  lineHeight: 'regular',
};

export const Text2 = styled(BaseText)``;
Text2.defaultProps = {
  fontSize: 'xs',
  lineHeight: 'regular',
};

export const Text3 = styled(BaseText)``;
Text3.defaultProps = {
  fontSize: 'sm',
  lineHeight: 'regular',
};

export const Text4 = styled(BaseText)``;
Text4.defaultProps = {
  fontSize: 'md',
  lineHeight: 'regular',
};

export const Text5 = styled(BaseText)``;
Text5.defaultProps = {
  fontSize: 'lg',
  lineHeight: 'regular',
};

export const Text6 = styled(BaseText)``;
Text6.defaultProps = {
  fontSize: 'xl',
  lineHeight: 'regular',
};

export const Text7 = styled(BaseText)``;
Text7.defaultProps = {
  fontSize: '2xl',
  lineHeight: 'regular',
};

export const Text8 = styled(BaseText)``;
Text8.defaultProps = {
  fontSize: '3xl',
  lineHeight: 'regular',
};

export const Text9 = styled(BaseText)``;
Text9.defaultProps = {
  fontSize: '4xl',
  lineHeight: 'regular',
};

export const Text10 = styled(BaseText)``;
Text10.defaultProps = {
  fontSize: ['4xl', '5xl'],
  lineHeight: 'regular',
};

export const Heading1Base = styled(BaseText)``;
Heading1Base.defaultProps = {
  fontSize: '3xl',
  fontFamily: 'AirbnbCereal Bold',
  
  
};

export const TextLargeBase = styled(BaseText)``;
TextLargeBase.defaultProps = {
  fontSize: 'lg',
};




import { styled } from 'styles';
import { Button as AButton } from '@chakra-ui/react';

// const BackButton = styled(AButton)``;
const BackButtonDefault = styled(AButton)``;
BackButtonDefault.defaultProps = {
  background: 'rgba(21, 60, 59, 0.05)',
  width: 'fit-content',
  padding: '0.3rem 0.7rem 0.3rem 0.5rem',
  borderRadius: '25px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

export const BackButton = ({
  children,
  ...props
}: any) => (
  <BackButtonDefault {...props}>
    {children}
  </BackButtonDefault>
)
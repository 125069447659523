import { Avatar, Box, Divider, Flex, Grid, GridItem, Spacer, Text, theme } from "@chakra-ui/react";
import { useUser } from "lib";
import { Spinner } from "components/atoms"
import { Navigate } from "react-router-dom"
import { Body0, Caption2, OptionGroup, PrimaryButton, Title1, Title3, Title4 } from "components/molecules";
import { CookingPot, PawPrint, SquareArrowOutUpRight, Users } from "lucide-react";
import { DetailBar } from "../../Dashboard/DetailBar";
import { Cleanliness, Cook, dateFormatter, GenderEnum, getDurationOfStay, Occupation, Pets, Smoke, Socialize } from "utils";
import Location from 'assets/images/LocationIcon.svg';
import Language from 'assets/images/LanguageIcon.svg';
import Education from 'assets/images/EducationIcon.svg';
import Profession from 'assets/images/ProfessionIcon.svg';
import Gender from 'assets/images/GenderIcon.svg';
import GlobeIcon from 'assets/images/GlobeIcon.svg';
import SmokeIcon from 'assets/images/SmokeIcon.svg';
import CleanlinessIcon from 'assets/images/CleanlinessIcon.svg';
import HomeIcon from 'assets/images/HomeIcon.svg';
import { TenantType } from "types";
import { EditModal } from "./EditModals";
import { useState } from "react";
import { countries_map, language_mappings, languages } from "utils/data";

const interests = ["Non-smoker", "Likes to clean", "Coffee", "Touring", "Music", "Reading"];

export const TenantProfileView = ({
  tenant
}: {
  tenant: TenantType
}) => {

  const { email, name, tenantData, age, location: currLocation, language, gender, profilepic } = tenant;
  const { 
    photoHighlights: pictures, 
    moveInDate, moveOutDate, 
    occupation, city: preferredLocation,
    smoke, cleanliness, pets, socialize,
    cook, descriptions
  } = tenantData;

  const [editModal, setEditModal] = useState<boolean>(false)
  const [editId, setEditId] = useState<string>('')

  const handleOpen = (id: string) => {
    setEditId(id)
    setEditModal(true)
  }

  const handleClose = () => {
    setEditModal(false)
  }

  return(
    <>
      {/* <Flex> */}
        <Flex 
          flexDir={'column'}
          border={'1px solid rgba(216, 220, 224, 1)'}
          borderRadius={'8px'}
          padding={'25px 10px'}
          alignItems={'center'}
        >
          <Avatar size={'xl'} src={`${process.env.REACT_APP_BASE_URL}/${profilepic}`} />
          <Flex 
          marginTop={'20px'}
          marginBottom={'10PX'}
          flexDir={'column'}>
            <Text px={5} alignItems={'left'} textStyle={'book'} >{name}</Text>
            <Text px={5} alignItems={'left'} fontSize={'small'} textStyle={'light'} >{email}</Text>
          </Flex>
          <PrimaryButton px={5} style={{width: '100%',borderRadius: '25px'}}>
            Edit Profile - 60%
          </PrimaryButton>
        </Flex>
      <Flex
      flexDir={'column'}
      border={'1px solid rgba(216, 220, 224, 1)'}
      width={'100%'}
      ml={'10px'}
      borderRadius={'8px'}
      padding={'20px 50px'}
      >
        <Flex justifyContent={'space-between'} width={'100%'} alignItems={'center'} my={5} >
          <Flex flexDir={'row'} alignItems={'baseline'}>
          <Title3>{name}</Title3>
          <Spacer mr={4} />
          <Title4 color='black.800'>age: {age}</Title4>
          </Flex>
          <Flex flexDir={'column'} alignItems={'center'}>
            <Box cursor={'pointer'} borderRadius={'50%'} backgroundColor={'rgba(0,0,0,0.05)'} padding={'2'}>
              <SquareArrowOutUpRight size={'16px'} />
            </Box>
            <Caption2 color={theme.colors.black[800]}>
              Share
            </Caption2>
          </Flex>
        </Flex>

        <Flex flexDir={'column'} marginBottom={'20PX'}>
          <Text textStyle={"bold"} fontSize="medium">Bio</Text>
          <Body0>
          Social media influencer dedicated to inspiring and empowering others through creativity and authenticity. Join me on this journey of self-expression and connection. 🌟
          </Body0>
        </Flex>

        <Grid width={'100%'} templateRows='repeat(1, 1fr)' templateColumns={[`repeat(1, 1fr)`, `repeat(2, 1fr)`]} gap={5}>
          <DetailBar openModal={() => handleOpen("movein")} id={"true"} icon={HomeIcon} name="Move-in date" value={<>{dateFormatter(moveInDate)}</>} />
          <DetailBar openModal={() => handleOpen("location")} id={"true"} icon={Location} name="Preferred location" value={countries_map[preferredLocation]} />
          <DetailBar openModal={() => handleOpen("movein")} id={"true"} icon={HomeIcon} name="Duration of Stay" value={getDurationOfStay(moveInDate, moveOutDate)} />
          <DetailBar openModal={() => handleOpen("language")} id={"true"} icon={Language} name="Languages" value={language.split(',').map((l: string) => language_mappings[l]).join(',')} />

          <DetailBar openModal={() => handleOpen("countries")} id={"true"} icon={Location} name="Current Location" value={countries_map[currLocation]} />
          <DetailBar openModal={() => handleOpen("occupation")} id={"true"} icon={Education} name="Education" value={Occupation[occupation]} />
          <DetailBar openModal={() => handleOpen("occupation")} id={"true"} icon={Profession} name="Profession" value={Occupation[occupation]} />
          <DetailBar openModal={() => handleOpen("countries")} id={"true"} icon={GlobeIcon} name="From" value={currLocation} />
          <DetailBar openModal={() => handleOpen("gender")} id={"true"} icon={Gender} name="Gender" value={GenderEnum[gender]} />
        </Grid>
        <Divider orientation="horizontal" my={5}/>

        <Grid width={'100%'} templateRows='repeat(1, 1fr)' templateColumns={[`repeat(1, 1fr)`, `repeat(2, 1fr)`]} gap={5}>
          <GridItem colSpan={[1, 2]}>
          <Title1>Additional Information</Title1>
          </GridItem>
          <DetailBar openModal={() => handleOpen("smoke")} id={"true"} icon={SmokeIcon} name="Smoke" value={Smoke[smoke]} />
          <DetailBar openModal={() => handleOpen("cleanliness")} id={"true"} icon={CleanlinessIcon} name="Cleanliness" value={Cleanliness[cleanliness]} />
          <DetailBar openModal={() => handleOpen("pets")} id={"true"} img={<PawPrint />} name="Pets" value={Pets[pets]} />
          <DetailBar openModal={() => handleOpen("socialize")} id={"true"} img={<Users />} name="Socializing" value={Socialize[socialize]} />
          <DetailBar openModal={() => handleOpen("cooking")} id={"true"} img={<CookingPot />} name="Cooking" value={Cook[cook]} />
        </Grid>
        <Divider orientation="horizontal" my={5}/>

        <Grid width={'100%'} templateRows='repeat(1, 1fr)' templateColumns={[`repeat(1, 1fr)`, `repeat(2, 1fr)`]} gap={2}>
          <GridItem colSpan={[1, 2]}>
          <Title1 marginBottom={'20PX'}>Interests</Title1>
          </GridItem>
        </Grid>
        <OptionGroup nonFunctional={true} selected={tenant.tenantData.descriptions} options={tenant.tenantData.descriptions} onChange={(v) => {}} columns={4} />
        {editModal && <EditModal user={tenant} id={editId} isOpen={editModal} handleClose={handleClose} />}
      </Flex>
    </>
  );
}
import { Avatar, Box, Divider, Flex, Grid, GridItem, Image, theme, VStack } from "@chakra-ui/react";
import { Body1, Caption2, Swiper, Title1, Title3, Body4, Underline2 } from "components/molecules";
import { SquareArrowOutUpRight } from "lucide-react";
import { ForwardedRef, forwardRef } from "react";
import RoomSize from 'assets/images/RoomSizeIcon.svg';
import Location from 'assets/images/LocationIcon.svg';
import Roommate from 'assets/images/RoommateIcon.svg';
import Calendar from 'assets/images/CalendarIcon.svg';
import SearchingFor from 'assets/images/SearchingForIcon.svg';
import Deposit from 'assets/images/DepositIcon.svg';
import { ListingType, PropertyType } from "types";
import { dateFormatter, GenderEnum } from "utils";
import { useGetProperty } from "lib/queries/useGetProperty";

// TODO: refactor code

type RoomDetailProps = {
  icon: any, 
  name: string, 
  value : any
}

const RoomDetail = ({icon, name, value}: RoomDetailProps) => (
  <GridItem colSpan={1}>
    <Flex flexDir={'row'} alignItems={'center'}>
      <Box cursor={'pointer'} marginRight={2} borderRadius={'50%'} backgroundColor={'rgba(0,0,0,0.05)'} padding={'2'}>
        <Image src={icon} />
      </Box>
      <Flex flexDir={'column'}>
        <Body1>
          {name}
        </Body1>
        <span style={{color: theme.colors.black[200]}}>{value}</span>
      </Flex>
    </Flex>
  </GridItem>
)

export const ApartmentPost = forwardRef((
{ 
  listing,
  styles={}
}: {
  listing: ListingType,
  styles?: React.CSSProperties
}, ref: ForwardedRef<HTMLDivElement|null>) => {
  const { 
    rent, 
    moveInDate, moveOutDate,
    gender,
    age,
    securityDeposit,
    property: propertyId
  } = listing;

  let { data: property } = useGetProperty(propertyId)

  if (property){
    const {
      roomPhotos: pictures,
      roomSize,
      roommates, roommateDetails,
      location
    } = property;
    return (
      <Flex 
      ref={ref}
      flexDir={['column', 'row']}
      alignItems={'center'} 
      justifyContent={'center'} 
      overflow={'hidden'}
      style={styles}
      paddingTop={[5, 10]}
      className="animate__animated animate__zoomIn"
      >
        <Flex 
        width={["90vw", "75vw"]} 
        flexDir={['column', 'row']} 
        border={'1px solid rgba(216, 220, 224, 1)'} 
        borderRadius={'16px'} padding={'16px'} maxH={'100%'}
        overflow={['scroll', 'none']}
        className="apartment-details"
        >
          {/* Room photos carousel  */}
          <Swiper border="none" width="100%" height="72vh" photoUrls={pictures?.map((r: string) => `http://localhost:3000/${r}`)} />
          <VStack padding={6} width={'100%'} maxH={'100%'} className="apartment-details" overflow={['none', 'scroll']}>
            <Flex justifyContent={'space-between'} width={'100%'} alignItems={'center'} >
              <Title3>{rent}€</Title3>
              <Flex flexDir={'column'} alignItems={'center'}>
                <Box cursor={'pointer'} borderRadius={'50%'} backgroundColor={'rgba(0,0,0,0.05)'} padding={'2'}>
                  <SquareArrowOutUpRight size={'16px'} />
                </Box>
                <Caption2 color={theme.colors.black[800]}>
                  Share
                </Caption2>
              </Flex>
            </Flex>
            <Grid width={'100%'} templateRows='repeat(1, 1fr)' templateColumns={[`repeat(1, 1fr)`, `repeat(2, 1fr)`]} gap={2}>
              <RoomDetail icon={RoomSize} name="Room Size" value={<>{roomSize} m<sup>2</sup></>} />
              <RoomDetail icon={Calendar} name="Availability" value={<>{dateFormatter(moveInDate, "ASAP")} - {dateFormatter(moveOutDate, "Unlimited")}</>} />
              <RoomDetail icon={Roommate} name="Roommates" value={<>{roommates} <i>(1 Male, 2 Female)</i></>} />
              <RoomDetail icon={SearchingFor} name="Searching For" value={<>{GenderEnum[gender ? gender : 3]}  | 18 - {age} Years old</>} />
              <RoomDetail icon={Location} name="Location" value={<>{location}</>} />
              <RoomDetail icon={Deposit} name="Deposit" value={<>{securityDeposit}€</>} />
            </Grid>
            <Divider orientation="horizontal" />
            <Grid width={'100%'} templateRows='repeat(1, 1fr)' templateColumns={[`repeat(1, 1fr)`, `repeat(2, 1fr)`]} gap={2}>
              <GridItem colSpan={[1, 2]}>
                <Title1>Roommates</Title1>
              </GridItem>
              {roommateDetails.length > 0 ? (
                roommateDetails.map(({name, age, gender, language, pfp}: any, i: number) => (
                  <GridItem key={`${name}-${i}`} backgroundColor={'rgba(0,0,0,0.05)'} borderRadius={'8px'} padding={3} colSpan={1}>
                    <Flex>
                      <Avatar marginRight={'10px'} height={'90px'} width={'90px'} src={`${process.env.REACT_APP_BASE_URL}/${pfp}`}  />
                      <VStack alignItems={'flex-start'} gap={0}>
                        <Body1>{name}</Body1>
                        {age ? <Body4>Age: {age}</Body4> : <></>}
                        {language ? <Body4>Speaks: {language}</Body4> : <></>}
                        <Underline2>See More</Underline2>
                      </VStack>
                    </Flex>
                  </GridItem>
                ))
              ) : (
                <Body1>None</Body1>
              )}          
            </Grid>
  
            <Divider orientation="horizontal" />
            <Grid width={'100%'} templateRows='repeat(1, 1fr)' templateColumns={[`repeat(1, 1fr)`, `repeat(2, 1fr)`]} gap={2}>
              <GridItem colSpan={[1, 2]}>
                <Title1>More Details</Title1>
              </GridItem>
              <RoomDetail icon={RoomSize} name="Room Size" value={<>11.2 m<sup>2</sup></>} />
              <RoomDetail icon={Calendar} name="Availability" value={<>1.10.2024 - 15.12.2024</>} />
              <RoomDetail icon={Roommate} name="Roommates" value={<>4 <i>(1 Male, 2 Female)</i></>} />
              <RoomDetail icon={SearchingFor} name="SearchingFor" value={<>Female  | 21 -32 Years old</>} />
              <RoomDetail icon={Location} name="Location" value={<>Kilianstr. 8, 33098, Padeborn</>} />
              <RoomDetail icon={Calendar} name="Availability" value={<>1.10.2024 - 15.12.2024</>} />
              <RoomDetail icon={Location} name="Location" value={<>Kilianstr. 8, 33098, Padeborn</>} />
              <RoomDetail icon={Deposit} name="Deposit" value={<>600€</>} />
              <RoomDetail icon={Location} name="Location" value={<>Kilianstr. 8, 33098, Padeborn</>} />
            </Grid>
          </VStack>
        </Flex>
      </Flex>
    )
  }
  return (
    <></>
  )
})
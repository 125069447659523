import { styled } from 'styles';
import { Button as AButton } from '@chakra-ui/react';


export const EnumButton = styled(AButton)`
  &:hover: {
  }
`;
EnumButton.defaultProps = {
  padding: "2rem 10rem",
  colorScheme: "white",
  color: "black.800",
  border: "1px solid #D0D5DD",
  boxShadow: "0px 4px 8px -2px rgba(16, 24, 40, 0.1)",
  width: "100%",
  _hover: { bg: "#ebedf0" },
};

import { Fragment } from "react";
import { Divider } from "@chakra-ui/react";
import { ListingType, PropertyType, TenantType } from "types";
import { Spinner } from "components/atoms";
import { useGetMatchedTenants } from "lib/queries/useGetMatchedTenants";
import { RenterAcceptedApplicationRow } from "./AcceptedApplicationRow";

export const RenterAcceptedApplications = () => {
  let { isFetching, data: applications} = useGetMatchedTenants()

  return(
    <>
      {isFetching ? (
        <Spinner />
      ) : (<></>)}
      {applications ? applications.map(({
        tenant,
        listing,
        property
      }: {
        tenant: TenantType, 
        listing: ListingType, 
        property: PropertyType
      }, i: number) => (
        <Fragment key={i}>
          <RenterAcceptedApplicationRow tenant={tenant} property={property} listing={listing} />
          {(i === (applications ? applications?.length - 1 : -1) ? <></> : <Divider my={3} orientation="horizontal" />)}
        </Fragment>
      )) : (<></>)}
    </>
  );
}
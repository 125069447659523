import { styled } from 'styles';
import {
  //
  Text2,
  Text3,
  Text4,
  Text6,
  Text7,
  Text8,
  Text10,
  Text5
} from 'components/atoms';

// text-10 (48px, 150%), bold
export const Heading0 = styled(Text10)``;
Heading0.defaultProps = {
  ...Heading0.defaultProps,
  lineHeight: 'regular',
  fontWeight: 'bold',
};

// text-10 (48px, 150%), bold
export const Heading3 = styled(Text10)``;
Heading3.defaultProps = {
  ...Heading0.defaultProps,
  lineHeight: 'regular',
  fontWeight: 'bold',
};

// text-6 (20px, 150%), bold
export const Heading1 = styled(Text6)`
  color: ${({color}: any) => (color ? color : 'black')}
`;
Heading1.defaultProps = {
  ...Heading1.defaultProps,
  as: 'h1',
  lineHeight: 'regular',
  fontWeight: 'bold',

};

// text-4 (16px, 150%), bold
export const Heading2 = styled(Text4)``;
Heading2.defaultProps = {
  lineHeight: 'regular',
  fontWeight: 'normal'
};

// text-7 (24px, 150%), bold
export const Title1 = styled(Text7)``;
Title1.defaultProps = {
  ...Title1.defaultProps,
  lineHeight: 'regular',
  fontWeight: 'bold',
};

export const Title2 = styled(Text8)``;
Title2.defaultProps = {
  ...Title2.defaultProps,
  lineHeight: 'regular',
  fontWeight: 'bold',
};

export const Title3 = styled(Text8)``;
Title3.defaultProps = {
  ...Title3.defaultProps,
  lineHeight: 'regular',
  fontWeight: '600',
};
export const Title4 = styled(Text5)`
  color: ${({color}: any) => (color ? color : 'black')}
`;
Title4.defaultProps = {
  ...Title4.defaultProps,
  lineHeight: 'regular',
  fontWeight: 'regular',
};


// text-6 (20px, 150%), bold
// export const SubTitle1 = styled(Text6)``;
// SubTitle1.defaultProps = {
//   lineHeight: "regular",
//   fontWeight: "bold",
// };

// text-4 (16px, 150%), bold
// export const Body0 = Text4;

export const Body0 = styled(Text4)``;
Body0.defaultProps = {
  ...Body0.defaultProps,
  lineHeight: 'regular',
};

export const CRtext = styled(Text4)``;
CRtext.defaultProps = {
  ...Body0.defaultProps,
  lineHeight: 'regular',
  position: 'absolute',
  bottom: '2',
  left: '2',
  color: 'black.500'
};
// text-4 (16px, 150%), bold
export const Body1 = styled(Text4)``;
Body1.defaultProps = {
  ...Body1.defaultProps,
  lineHeight: 'regular',
  fontWeight: 'bold',
};

// text-3 (14px, 150%), bold
export const Body2 = styled(Text3)``;
Body2.defaultProps = {
  ...Body2.defaultProps,
  lineHeight: 'regular',
  fontWeight: 'bold',
};


// text-3 (14px, 150%), bold
export const Body4 = styled(Text3)``;
Body4.defaultProps = {
  ...Body4.defaultProps,
  lineHeight: 'regular',
  fontWeight: 'regular',
};


export const Body3 = styled(Text4)``;
Body0.defaultProps = {
  ...Body0.defaultProps,
  lineHeight: 'regular'
};


// text-4 (16px, 150%), bold
// export const Button1 = styled(Text4)``;
// Button1.defaultProps = {
//   lineHeight: "regular",
//   fontWeight: "bold",
// };

// text-2 (13px, 150%), bold
export const Caption1 = styled(Text2)``;
Caption1.defaultProps = {
  ...Caption1.defaultProps,
  lineHeight: 'regular',
  fontWeight: 'bold'
};

// text-2 (13px, 150%), regular
export const Caption2 = styled(Text2)``;
Caption2.defaultProps = {
  ...Caption2.defaultProps,
  lineHeight: 'regular',
  fontWeight: 'normal',
};

// text-3 (14px, 150%), bold
export const Underline1 = styled(Text3)``;
Underline1.defaultProps = {
  ...Underline1.defaultPtops,
  lineHeight: 'regular',
  fontWeight: 'bold',
};

// text-3 (14px, 150%), bold
export const Underline2 = styled(Text3)``;
Underline2.defaultProps = {
  ...Underline2.defaultProps,
  lineHeight: 'regular',
  fontWeight: 'regular',
  textDecoration: 'underline'
};

// Error text
export const ErrorText = styled(Text3)``;
ErrorText.defaultProps = {
  ...ErrorText.defaultProps,
  lineHeight: 'regular',
  color: 'red',
  textAlign: 'center'
};


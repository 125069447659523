import { Avatar, Box, Divider, Flex, Grid, GridItem, Spacer, Text, theme } from "@chakra-ui/react";
import { useUser } from "lib";
import { Spinner } from "components/atoms"
import { Navigate } from "react-router-dom"
import { Body0, Caption2, OptionGroup, PrimaryButton, Title1, Title3, Title4 } from "components/molecules";
import { SquareArrowOutUpRight } from "lucide-react";
import { DetailBar } from "../Dashboard/DetailBar";
import { dateFormatter } from "utils";
import { TenantProfileView } from "./Tenant";
import { TenantType } from "types";

export const ProfileView = () => {
  const { data: user, isFetching } = useUser()

  if (isFetching){
    return(
      <Flex>
        <Spinner />
      </Flex>
    )
  }
  if (user){
    return(
      <Flex>
        <TenantProfileView tenant={user} />
      </Flex>
    );  
  }
  else{
    return(
      <Navigate to="/login?redirectTo=/profile" />
    );
  }
}
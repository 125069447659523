import { useQuery } from "@tanstack/react-query"
import { api } from "lib/api-client"
import { ListingType, PropertyType } from "types"

export const useGetShortlistListing = () => {
  let query = useQuery<{listing: ListingType, property: PropertyType}[]>({
    queryKey: ['getShortlistListings'],
    queryFn: async () => {
      let r = await api.get(`/listing/shortlist`, {}, true)
      return r.data;
    },
    refetchOnWindowFocus: true,
    // data will be refetched on window focus after 30 mins
    staleTime: 30 * 60 * 1000
  })
  return query;
}
import { useContext } from "react"
import { AllPagesContext } from "context"
import { ActivePageContext } from "context";

export const useCurrentPage = () => {
  let { allPages, setAllPages } = useContext(AllPagesContext);
  let { activePage: activePageIndex, setActivePage } = useContext(ActivePageContext);
  let activePage = allPages[activePageIndex];


  return {allPages, setAllPages, activePageIndex, setActivePage, ...activePage };
}
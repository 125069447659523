import { Select } from "chakra-react-select";
import { FormLabel } from "components/atoms";
import { FormikValues, useFormikContext } from "formik";
import { values } from "ramda";
import { countries, languages } from "utils/data";

export const EditLanguage = () => {
  const { values, setFieldValue }: FormikValues = useFormikContext();

  return(
    <>
      <Select
        name="language"
        options={languages}
        placeholder="Select languages"
        isMulti
        closeMenuOnSelect={false}
        selectedOptionStyle="check" // Optional: Highlight selected items
        value={languages.filter((lang) =>
          values.language?.includes(lang.value)
        )}
        onChange={(selected) => {
          const selectedValues = selected.map((option) => option.value);
          console.log(selectedValues.join(','))
          setFieldValue("language", selectedValues.join(','));
        }}
      />
    </>
  );
}
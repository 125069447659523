import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ChatMessages, PropertyAddValues } from "types";
import { getFormData, api } from "lib";
import { useNotifications } from "components/organisms";

export const useSeenMessage = () => {

  const mutation = useMutation({
    mutationKey: ['seenMessage'],
    mutationFn: (msgData: {from: string}) => {
      return api.put('/chat/seen', {body: JSON.stringify(msgData)}, true, 'json');
    }
  })
  return mutation;
}
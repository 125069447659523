import { FormikValues, useFormikContext } from 'formik';
import { Spacer, VStack } from '@chakra-ui/react';

import { InputField, Heading1, PrimaryButton, SecondaryButton } from 'components/molecules';
import { Spinner } from 'components/atoms';

export const CompleteProfile = (): JSX.Element => {
  let { values, isSubmitting }:FormikValues = useFormikContext();
  return(
    <>
    <Heading1>Create Your Account?</Heading1>
    <Spacer my={4} flex={0} />
    <VStack gap={1}>
      <InputField 
        name='user.email'
        value={values.user.email}
        isRequired={true}
        label='Email'
        placeholder='Enter your email'
        />
      <Spacer my={2} flex={0} />
      <InputField 
        name='user.password'
        value={values.user.password}
        isRequired={true}
        label='Password'
        type='password'
        placeholder='••••••••'
        />
      <InputField 
        name='user.confirmPassword'
        value={values.user.confirmPassword}
        isRequired={true}
        label='Confirm Password'
        type='password'
        placeholder='••••••••'
        />
      <Spacer my={2} flex={0} />

      <PrimaryButton isDisabled={isSubmitting} size='md' type="submit" width="100%" padding="10px">
        {isSubmitting ? <Spinner thickness='1px' /> : 'Sign up'}
      </PrimaryButton>
      <SecondaryButton size='md' width="100%" padding="10px">Sign up with Google</SecondaryButton>
    </VStack>
    </>
  )
}
import { Img } from "@chakra-ui/react";
import { FormikValues, useFormikContext } from "formik";

import { OptionGroup, Heading1, NextButton } from "components/molecules";
import { useCurrentPage } from "lib";
import { LogoWithName } from "components/organisms";
import { Heading1Base } from "components/atoms";


export const Description = (): JSX.Element => {
  let { options, sideText, fields, nextBtn, setActivePage, activePageIndex } = useCurrentPage();

  let { setFieldValue, values }: FormikValues = useFormikContext();

  const saveField = (items: string[]) => {
    setFieldValue(fields[0], items);
  }

  return(
    <>
      <LogoWithName noName={true} size="70px" />
      <Heading1Base margin={'20px'}>{sideText}</Heading1Base>
      <OptionGroup 
      selected={values.tenantData.descriptions}
      options={options ? options : []}
      onChange={saveField}
      columns={5}
      fontSize="sm"
      />
      {nextBtn ? (
        <NextButton onClick={() => setActivePage(activePageIndex+1)}>Next</NextButton>
      ) : (<></>)}
    </>
  );
}
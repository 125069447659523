import {
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
} from '@chakra-ui/react';
import { Link as RLink, useLocation } from 'react-router-dom';
import { styled } from 'styles';
// import { primaryColor } from './../../../utils/colors';

interface LinkProps extends ChakraLinkProps {
  href: string;
  _active?: ChakraLinkProps;
}

export const Link: React.FC<LinkProps> = ({
  //
  href,
  _active,
  children,
  ...props
}) => {
  const location = useLocation();
  const isActive = location.pathname === href;
  const inactiveProps = {
    _hover: { textDecoration: 'underline' },
    ...props,
  };
  const activeProps = {
    _hover: { textDecoration: 'underline' },
    ...props,
    ..._active,
  };
  const linkProps = isActive ? activeProps : inactiveProps;
  return (
    // <RLink to={href}>
      <ChakraLink {...linkProps} as={RLink} to={href} >
        {children}
      </ChakraLink>
    // </RLink>
  );
};

export const PrimaryLink = styled(Link)``;
PrimaryLink.defaultProps = {
  color: 'base.primary',
};

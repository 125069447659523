
import { theme } from '@chakra-ui/react';
import { Info, CircleAlert, CircleX, CircleCheck, X } from 'lucide-react';
import { useEffect, useRef } from 'react';
import './index.css';
import { notif_time } from 'utils';

const icons = {
  info: <Info  height={'1.5rem'} width={'1.5rem'} color={theme.colors.blue[500]} aria-hidden="true" />,
  success: <CircleCheck  height={'1.5rem'} width={'1.5rem'} color={theme.colors.green[500]} aria-hidden="true" />,
  warning: <CircleAlert  height={'1.5rem'} width={'1.5rem'} color={theme.colors.yellow[500]}  aria-hidden="true" />,
  error: <CircleX height={'1.5rem'} width={'1.5rem'} color={theme.colors.red[500]} aria-hidden="true" />,
};

export type NotificationProps = {
  notification: {
    id: string;
    type: keyof typeof icons;
    title: string;
    message?: string;
  };
  onDismiss: (id: string) => void;
};

export const Notification = ({
  notification: { id, type, title, message },
  onDismiss,
}: NotificationProps) => {
  let ref = useRef<HTMLDivElement|null>(null);
  let wait = async (sec: number) => new Promise((res) => setTimeout(res, sec*1000));

  const dismiss = async () => {
    ref.current?.classList.add('animate__backOutUp');
    await wait(1);
    onDismiss(id);
  }

  useEffect(() => {
    let t = setTimeout(dismiss, notif_time*1000);
    return () => {
      clearTimeout(t);
    }
  }, []);

  return (
    <>
    <div ref={ref} className='animate__animated animate__backInDown' style={{"display":"flex","marginTop":"1rem","flexDirection":"column","alignItems":"center","width":"100%",
      // "@media (min-width: 640px)":{"alignItems":"flex-end"}
    }}>
      <div style={{"overflow":"hidden","borderRadius":"0.5rem","boxShadow":"0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)","width":"100%","maxWidth":"24rem","backgroundColor":"#ffffff","pointerEvents":"auto"}}>
        <div style={{"padding":"1rem"}} role="alert" aria-label={title}>
          <div style={{"display":"flex","alignItems":"flex-start"}}>
            <div style={{"flexShrink":"0", "marginTop": 'auto', "marginBottom": 'auto'}}>{icons[type]}</div>
            <div style={{"paddingTop":"0.125rem","marginLeft":"0.75rem","flex":"1 1 0%","width":"0"}}>
              <p style={{"fontSize":"0.875rem","lineHeight":"1.25rem","fontWeight":500,"color":theme.colors.gray[900]}}>{title}</p>
              <p style={{"marginTop":"0.25rem","fontSize":"0.875rem","lineHeight":"1.25rem","color":theme.colors.gray[500]}}>{message}</p>
            </div>
            <div style={{"marginTop": 'auto', "marginBottom": 'auto', "display":"flex","marginLeft":"1rem","flexShrink":"0"}}>
              <button
                style={{"display":"inline-flex","borderRadius":"0.375rem","color":"#9CA3AF","backgroundColor":"#ffffff"
                  // ,":hover":{"color":"#6B7280"}
                }}
                onClick={dismiss}
              >
                <span style={{"position":"absolute","width":"1px","height":"1px","padding":"0","margin":"-1px","overflow":"hidden","clip":"rect(0, 0, 0, 0)","whiteSpace":"nowrap","borderWidth":"0"}}>Close</span>
                <X className="size-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};
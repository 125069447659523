import { useEffect } from 'react';
import { AppProvider } from './provider';
import { AppRouter } from './router';
import { socket } from './socket'
import { useQueryClient } from '@tanstack/react-query';
import { ChatMessages } from 'types';

function App() {

  return (
    <AppProvider>
      <AppRouter />
    </AppProvider>
  );
}

export default App;

import { useLogin } from 'lib';
import { useNotifications } from '../Notifications';
import { createFakeGoogleWrapper, GoogleSignin } from 'components/molecules';

export const GoogleSignInButton = () => {
  const login = useLogin({
    onError: (error: any) => {
      useNotifications.getState().addNotification({
        type: 'error',
        title: 'Error',
        message: error.message ? error.message : 'Failed to login',
      });
    }
  });

  const handleCredentialResponse = async (e: any) => {
    login.mutate({token: e.credential})
  }

  // Now we have a wrapper to click
  const googleButtonWrapper = createFakeGoogleWrapper(handleCredentialResponse);

  return (
    <GoogleSignin onClick={googleButtonWrapper.click} />
  );
}

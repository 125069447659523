import { useQuery } from "@tanstack/react-query"
import { api } from "lib/api-client"
import { useUser } from "lib/auth";
import { ListingType } from "types"

export const useGetListings = (page: string) => {
  let { data } = useUser();
  let query = useQuery<ListingType>({
    queryKey: ['listings', page],
    queryFn: async () => {
      let r;
      if (data){
        r = await api.get(`/listing/auth${page ? `?cursor=${page}` : ""}`, {}, true)
      }
      else{
        r = await api.get(`/listing${page ? `?cursor=${page}` : ""}`)
      }
      return r.data;
    },
    refetchOnWindowFocus: true,
    // data will be refetched on window focus after 30 mins
    staleTime: 30 * 60 * 1000
  })
  return query;
}
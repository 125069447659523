import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import { ApartmentPost } from "components/organisms";
import { ListingType } from "types";
import './index.css';

export const ListingPostModal = ({
  listing, 
  open, 
  setClose
} : {
  listing: ListingType | null,
  open: boolean,
  setClose: () => void
}) => (
  <Modal id="aparmtment-post-modal" onClose={setClose} size={['6xl']} isOpen={open}>
    <ModalOverlay overflowY={'hidden'} />
    <ModalContent id="contenttt" height={`calc(100vh - 7.5rem)`}>
      <ModalHeader>Modal Title</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        {listing ? <ApartmentPost styles={{height: `calc(100vh - 13.5rem)`}} listing={listing} /> : <></>}
      </ModalBody>
      <ModalFooter>
      </ModalFooter>
    </ModalContent>
  </Modal>

)
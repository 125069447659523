import { Fragment, useState } from "react";
import { Divider } from "@chakra-ui/react";
import { ListingType, PropertyType } from "types";
import { Spinner } from "components/atoms";
import { ListingPostModal } from "components/molecules";
import { useGetMatchedListings } from "lib/queries/useGetMatchedListings";
import { TenantAcceptedApplicationRow } from "./AcceptedApplicationRow";

export const TenantAcceptedApplications = () => {
  let { isFetching, data: applications} = useGetMatchedListings()
  const [modal, setModal] = useState(false)
  const [modalListing, setModalListing] = useState<ListingType|null>(null)

  const openModal = (listing: ListingType) => {
    setModalListing(listing)
    setModal(true)
  }

  const closeModal = () => {
    setModal(false)
    setModalListing(null)
  }

  return(
    <>
      {isFetching ? (
        <Spinner />
      ) : (<></>)}
      {applications ? applications.map(({
        listing,
        property
      }: {
        listing: ListingType,
        property: PropertyType
      }, i: number) => (
        <Fragment key={i}>
          <TenantAcceptedApplicationRow 
          listing={listing} 
          property={property} 
          viewApartment={openModal}
          />
          {(i === (applications ? applications?.length - 1 : -1) ? <></> : <Divider my={3} orientation="horizontal" />)}
        </Fragment>
      )) : (<></>)}
        <ListingPostModal open={modal} setClose={closeModal}
          listing={modalListing}
        />

    </>
  ); 
}

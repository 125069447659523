import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import { Heading0, Heading1 } from 'components/molecules';
import { Spinner } from 'components/atoms';

export const LoadingModal = () => (
  <Modal isCentered isOpen={true} onClose={()=>{}}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader></ModalHeader>
      <ModalBody textAlign={'center'}>
        <Spinner/>
        <Heading1>
          Creating property...                  
        </Heading1>
      </ModalBody>
      <ModalFooter></ModalFooter>
    </ModalContent>
  </Modal>

)
import { useMutation } from "@tanstack/react-query";
import { PropertyAddValues } from "types";
import { getFormData, api } from "lib";
import { useNotifications } from "components/organisms";

export const useCreateProperty = () => {
  const mutation = useMutation({
    mutationKey: ['createProperty'],
    mutationFn: (property: PropertyAddValues) => {
      let obj: any = {...property};
      delete obj["moveIn"];
      delete obj["moveOut"];

      let property_fd = getFormData(obj);
      return api.post('/property', {body: property_fd}, true);
    },
    onError: (error) => {
      useNotifications.getState().addNotification({
        type: 'error',
        title: 'Failed to create property',
        message: error.message ? error.message : 'Try again',
      });
    }
  })
  return mutation;
}
import { Spacer } from "@chakra-ui/react";
import { InputDateField, RadioField } from "components/molecules";
import { Formik, FormikValues, useFormikContext } from "formik";
import { useEffect } from "react";

export const EditMoveInMoveOutDate = () => {

  const { values, setFieldValue }: FormikValues = useFormikContext();

  useEffect(() => {
    setFieldValue('tenantData.moveIn', values.tenantData.moveInDate ? 'later' : 'now')
    setFieldValue('tenantData.moveOut', values.tenantData.moveOutDate ? 'later' : 'now')
  }, []);

  return(
    <>
      <RadioField 
        isRequired={true}
        label='Preferred move-in date?'
        options={{'now': 'As soon as possible', 'later': 'Select Date'}}
        value={values.tenantData.moveIn}
        name='tenantData.moveIn'
      />
      {values.tenantData.moveIn === 'later' && <InputDateField 
        isRequired={true}
        isDisabled={values.tenantData.moveIn === 'now'}
        label={'Date'}
        minDate={new Date()}
        value={values.tenantData.moveInDate}
        name="tenantData.moveInDate"
      />}
      <Spacer my={5} flex={0} />
      <RadioField 
        isRequired={true}
        label='For how long is the apartment available?'
        options={{'now': 'As long as possible', 'later': 'Select date'}}
        value={values.tenantData.moveOut}
        name='tenantData.moveOut'
      />
      {values.tenantData.moveOut === 'later' && <InputDateField 
      isRequired={true}
      minDate={values.tenantData.moveInDate ? new Date(values.tenantData.moveInDate) : new Date()}
      isDisabled={values.tenantData.moveOut === 'now'}
      label={'Date'}
      name="tenantData.moveOutDate"
      value={values.tenantData.moveOutDate}
      />}

    </>
  );
}
import { useRef } from "react";
import {
  Grid,
  GridItem,
  Image,
  Box,
  Icon,
  IconButton,
  Spacer,
} from "@chakra-ui/react";
import { AddIcon, CloseIcon } from "@chakra-ui/icons";
import { FormikValues, useFormikContext } from "formik";
import { ErrorText, Heading1 } from "components/molecules";

export const RoomPhotos = (): JSX.Element => {
  const { setFieldValue, setTouched, errors, touched, values, validateField }: FormikValues =
    useFormikContext();
  const imgInput = useRef<HTMLInputElement | null>(null);

  const fileChange = (e: any) => {
    if (e.target.files.length > 0) {
      const newFiles = Array.from(e.target.files);
      setFieldValue("property.roomPhotos", [
        ...(values.property?.roomPhotos || []),
        ...newFiles,
      ]);
      setTimeout(() => {
        validateField("property.roomPhotos");
        setTouched({ property: { roomPhotos: true } });
      }, 1000);
    }
  };

  const removeImage = (index: number) => {
    const updatedPhotos = values.property.roomPhotos.filter(
      (_: any, i: number) => i !== index
    );
    setFieldValue("property.roomPhotos", updatedPhotos);
  };

  const MAX_IMAGES = 5; // Maximum number of placeholders

  return (
    <>
      <Heading1>Photo of your room</Heading1>
      <Spacer my={4} flex={0} />
      <Grid
        position="relative"
        width="100%"
        justifyContent="center"
        alignItems="center"
        borderRadius="8px"
        border="1px dashed rgba(0, 0, 0, 0.3)"
        borderStyle="dashed"
        style={{
          borderImage:
            "repeating-linear-gradient(90deg, rgba(0,0,0,0.3), rgba(0,0,0,0.3) 15px, transparent 15px, transparent 30px) 1",
        }}
        padding={4}
        gap={4}
        templateColumns="repeat(auto-fit, minmax(100px, 1fr))"
      >
        {Array.from({ length: MAX_IMAGES }).map((_, index) => (
          <GridItem key={index} position="relative">
            {values.property?.roomPhotos?.[index] ? (
              <Box position="relative">
                <Image
                  src={URL.createObjectURL(values.property.roomPhotos[index])}
                  alt={`Room Photo ${index + 1}`}
                  borderRadius="8px"
                  boxSize="150px"
                  objectFit="cover"
                />
                <IconButton
                  aria-label="Remove Image"
                  icon={<CloseIcon />}
                  size="xs"
                  position="absolute"
                  top="4px"
                  right="4px"
                  backgroundColor="rgba(0,0,0,0.6)"
                  color="white"
                  _hover={{ backgroundColor: "red.500" }}
                  onClick={() => removeImage(index)}
                />
              </Box>
            ) : (
              <Box
                borderRadius="8px"
                boxSize="150px"
                backgroundColor="gray.200"
                display="flex"
                alignItems="center"
                justifyContent="center"
                cursor="pointer"
                onClick={() => imgInput.current?.click()}
              >
                <Icon as={AddIcon} boxSize={6} color="gray.500" />
              </Box>
            )}
          </GridItem>
        ))}
      </Grid>
      <input
        multiple
        ref={imgInput}
        onChange={fileChange}
        style={{ display: "none" }}
        type="file"
        accept="image/png,image/jpeg,image/jpg"
      />
      {errors.property?.roomPhotos && touched.property?.roomPhotos && (
        <ErrorText>{errors.property.roomPhotos}</ErrorText>
      )}
    </>
  );
};
